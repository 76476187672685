import { Close, KeyboardReturn } from "@mui/icons-material";
import {
  Card,
  Collapse,
  Divider,
  Fade,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { teal } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { Chart } from "react-chartjs-2";
import { Disbursement, ProjectDto } from "../api/GetDisbursementHistory";
import useTheme from "@mui/system/useTheme";

type ProjectTableProps = {
  category: Disbursement | Array<Disbursement> | null;
  cpiAdjustProjects?: boolean;
};

export default function ProjectTable({
  category,
  cpiAdjustProjects = false,
}: ProjectTableProps) {
  const [selectedProject, setSelectedProject] = useState<ProjectDto | null>(
    null
  );

  const [projects, setProjects] = useState<ProjectDto[]>([]);

  const theme = useTheme();
  const isMobilePhone = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (!category) return;

    var projects: ProjectDto[] = [];
    if (Array.isArray(category)) {
      category.forEach((cat) =>
        cat.projects.forEach((project) => (project.year = cat.year))
      );
      let relevantProjects = category.flatMap((item) => item.projects);
      relevantProjects.sort((a, b) =>
        cpiAdjustProjects
          ? b.cpiAdjustedAmount - a.cpiAdjustedAmount
          : b.amount - a.amount
      );
      if (relevantProjects.length > 0) {
        setSelectedProject(relevantProjects[0]);
      }
      setProjects(
        relevantProjects.sort((a, b) =>
          cpiAdjustProjects
            ? b.cpiAdjustedAmount - a.cpiAdjustedAmount
            : b.amount - a.amount
        )
      );
      return;
    }

    category.projects.forEach((project) => (project.year = category.year));
    projects = category.projects;
    projects.sort((a, b) => b.amount - a.amount);
    setProjects(projects);
    if (projects.length > 0) {
      setSelectedProject(projects[0]);
    }
  }, [category]);

  return (
    <Grid item container xs={12} sx={{ mt: 1 }} spacing={5}>
      <Grid item xs={12} md={6}>
        <Card>
          <TableContainer sx={{ height: 350 }}>
            <Table
              size="small"
              sx={{
                ".MuiTableCell-head": {
                  fontWeight: "bold",
                },
                "& th": {
                  fontSize: 10,
                },
                "& td": {
                  fontSize: 10,
                },
                "& .MuiTableRow-root:hover": {
                  backgroundColor: "primary.light",
                  cursor: "pointer",
                  "& td": { color: "primary.contrastText" },
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell width="70%">Prosjekt</TableCell>
                  <TableCell width="15%" align="right">
                    År
                  </TableCell>
                  <TableCell width="15%" align="right">
                    Beløp
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projects.map((item) => (
                  <TableRow
                    onClick={() =>
                      setSelectedProject((state) => {
                        if (
                          state?.name === item.name &&
                          state.year === item.year
                        )
                          return null;
                        return item;
                      })
                    }
                    sx={{
                      backgroundColor:
                        selectedProject?.name === item.name &&
                        selectedProject.year === item.year
                          ? "primary.light"
                          : undefined,
                      "& td": {
                        color:
                          selectedProject?.name === item.name &&
                          selectedProject.year === item.year
                            ? "primary.contrastText"
                            : undefined,
                      },
                    }}
                  >
                    <TableCell>{item.name}</TableCell>
                    <TableCell align="right">{item.year}</TableCell>
                    <TableCell align="right">
                      {cpiAdjustProjects
                        ? item.cpiAdjustedAmount.toLocaleString("en-US")
                        : item.amount.toLocaleString("en-US")}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Totalt</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}></TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align="right">
                    {projects
                      .reduce(
                        (value, item) =>
                          (value += cpiAdjustProjects
                            ? item.cpiAdjustedAmount
                            : item.amount),
                        0
                      )
                      .toLocaleString("en-US")}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Collapse in={!selectedProject}>
          <Grid item xs={12}>
            {/* <Waterfall
              values={[
                {
                  value: Math.round(
                    ((category?.budget ?? 0) -
                      (category?.projects.reduce(
                        (value, item) => (value += item.amount),
                        0
                      ) ?? 0)) *
                      0.3
                  ),
                  name: "Tildelt, ikke utbetalt",
                },
                {
                  value: Math.round(
                    ((category?.budget ?? 0) -
                      (category?.projects.reduce(
                        (value, item) => (value += item.amount),
                        0
                      ) ?? 0)) *
                      0.7
                  ),
                  name: "Midler ikke allokert",
                },
              ]}
              options={{
                startingValue:
                  category?.projects.reduce(
                    (value, item) => (value += item.amount),
                    0
                  ) ?? 0,
                startingLabel: "Utdelte midler",
                endLabel: "Totalt budsjett for året",
              }}
            /> */}
          </Grid>
        </Collapse>
        <Fade in={selectedProject !== null}>
          <Grid item container xs={12} spacing={1}>
            <Grid item container xs={12}>
              <Grid
                item
                xs={10}
                container
                justifyContent={"center"}
                sx={{ fontWeight: "bold" }}
              >
                {selectedProject?.name ?? ""}
              </Grid>
              <Grid item container xs={2} justifyContent={"right"}>
                <Tooltip title="Lukk">
                  <IconButton
                    size="small"
                    onClick={() => setSelectedProject(null)}
                  >
                    <Close />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={2}>
              {/* <Grid item container xs={12}>
        <Grid item xs={12} sm={6} container spacing={2}>
          <LineItem
            name="Kontaktperson"
            value={"Eksempel 1"}
          />
          <LineItem
            name="E-post"
            value={"eksempel1@gmail.com"}
          />
        </Grid>
        <Grid item xs={12} sm={6} container spacing={2}>
          <LineItem
            name="Tildelt beløp"
            value={selectedProject.amount.toLocaleString(
              "en-US"
            )}
          />
        </Grid>
      </Grid> */}
              <Grid item container xs={12} spacing={2}>
                {/* <Grid item xs={12}>
        <Divider />
      </Grid> */}
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Utbetalinger</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Chart
                    type="bar"
                    height={isMobilePhone ? 170 : 200}
                    /* width={"90%"} */
                    data={{
                      labels: [2023, 2024, 2025, 2026, 2027],
                      datasets: [
                        {
                          label: "Utførte utbetalinger",
                          data: [
                            cpiAdjustProjects
                              ? selectedProject?.cpiAdjustedAmount ?? 0
                              : (selectedProject?.amount ?? 0) * 0.5,
                            0,
                            0,
                            0,
                            0,
                          ],
                          backgroundColor: (item: any, context: any) => {
                            return teal[300];
                          },
                        },
                        {
                          label: "Framtidige utbetalinger",
                          data: [
                            cpiAdjustProjects
                              ? selectedProject?.cpiAdjustedAmount ?? 0
                              : selectedProject?.amount ?? 0 * 0.1,
                            cpiAdjustProjects
                              ? selectedProject?.cpiAdjustedAmount ?? 0
                              : selectedProject?.amount ?? 0 * 0.2,
                            cpiAdjustProjects
                              ? selectedProject?.cpiAdjustedAmount ?? 0
                              : selectedProject?.amount ?? 0 * 0.1,
                            cpiAdjustProjects
                              ? selectedProject?.cpiAdjustedAmount ?? 0
                              : selectedProject?.amount ?? 0 * 0.1,
                            0,
                          ],
                          datalabels: {
                            display: false,
                            color: "white",
                            backgroundColor: "black",
                            formatter: function (value: any, context: any) {
                              console.log(value);

                              return Math.round(value / 1000).toLocaleString(
                                "en-US"
                              );
                            },
                          },
                          backgroundColor: (item: any, context: any) => {
                            return teal[100];
                          },
                        },
                      ],
                    }}
                    options={{
                      plugins: {
                        title: {
                          display: false,
                          text: "",
                        },
                        legend: {
                          display: false,
                        },

                        datalabels: {
                          display: false,
                        },
                      },
                      scales: {
                        x: {
                          stacked: true,
                        },
                        y: {
                          stacked: true,
                        },
                      },
                      responsive: true,
                      maintainAspectRatio: false,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Fade>
      </Grid>
    </Grid>
  );
}
