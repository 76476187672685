import {
  Grid,
  Slider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  Collapse,
  Fade,
  TableContainer,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Dividend, GetDividends } from "../../api/GetDividends";
import moment from "moment";
import DialogWindow from "../../components/DialogWindow";
import { Cashflow, GetCashflows } from "../../api/GetCashflows";
import { GetWealthTax, WealthTax } from "../../api/GetWealthTax";
import { Calculate } from "@mui/icons-material";

type TaxesProps = {
  client: string;
};

export default function Taxes({ client }: TaxesProps) {
  const [dividend, setDividend] = useState<number>(0);
  const [dividends, setDividends] = useState<Dividend[]>([]);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [cashflows, setCashflows] = useState<Cashflow[]>([]);
  const [totalPrepayments, setTotalPrepayments] = useState<number>(0);
  const [wealthTax, setWealthTax] = useState<WealthTax[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>(2023);
  const [popupMode, setPopupMode] = useState<"dividends" | "calculator">(
    "dividends"
  );
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getData();
  }, [client, selectedYear]);

  useEffect(() => {
    setTotalPrepayments(
      cashflows
        .filter((item) => item.taxPrepayment === true)
        .reduce((value, item) => (value += item.amount), 0)
    );
  }, [cashflows]);

  async function getData() {
    try {
      if (!client || !selectedYear) return;
      setLoading(true);

      let [dividends, cashflows, wealthTax] = await Promise.all([
        GetDividends({
          clients: [client],
          periodFrom: selectedYear.toString() + "01",
          periodTo: selectedYear.toString() + "12",
        }),
        GetCashflows({
          clients: [client],
          periodFrom: selectedYear.toString() + "01",
          periodTo: selectedYear.toString() + "12",
        }),
        GetWealthTax({ clients: [client], years: [selectedYear] }),
      ]);
      setDividends(dividends);
      setWealthTax(wealthTax);
      setCashflows(cashflows);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  function getWealthTaxAmount() {
    return (
      wealthTax.reduce((value, item) => (value += item.amount), 0) * 0.8 * 0.011
    );
  }

  return (
    <Grid item container xs={12}>
      <DialogWindow
        onClose={() => setShowDetails(false)}
        open={showDetails}
        title={
          popupMode === "dividends"
            ? "Registrerte utbytter"
            : "Utbyttekalkulator"
        }
      >
        {popupMode === "dividends" ? (
          <TableContainer sx={{ p: 5, maxHeight: "50vh" }}>
            <Table sx={{ width: "100%" }}>
              <TableHead>
                <TableRow>
                  <TableCell>Dato</TableCell>
                  <TableCell>Beskrivelse</TableCell>
                  <TableCell align="right">Beløp</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dividends.map((item) => (
                  <TableRow>
                    <TableCell>
                      {moment(item.date).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell>{item.description}</TableCell>
                    <TableCell align="right">
                      {item.amount.toLocaleString("en-US")}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow
                  sx={{
                    "& td": {
                      fontWeight: "bold",
                      backgroundColor: "lightgrey",
                    },
                  }}
                >
                  <TableCell>Totalt</TableCell>
                  <TableCell></TableCell>
                  <TableCell align="right">
                    {dividends
                      .reduce((value, item) => (value += item.amount), 0)
                      .toLocaleString("en-US")}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Grid
            item
            container
            xs={12}
            sx={{ p: 5 }}
            alignContent={"flex-start"}
            spacing={2}
          >
            <Grid item container xs={12} sm={4} alignContent={"center"}>
              <Grid
                item
                container
                xs={12}
                sx={{ color: "text.secondary", fontSize: 12 }}
                justifyContent="center"
              >
                <Grid item>TILLEGGSUTBYTTE</Grid>
              </Grid>
              <Grid
                item
                container
                justifyContent="center"
                xs={12}
                sx={{ color: "text.primary", fontSize: 16 }}
              >
                <Grid item>{(dividend * 1000).toLocaleString("en-US")}</Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={4} alignContent="center">
              <Grid
                item
                container
                xs={12}
                sx={{ color: "text.secondary", fontSize: 12 }}
                justifyContent="center"
              >
                <Grid item>SKATT PÅ TILLEGGSUTBYTTE</Grid>
              </Grid>
              <Grid
                item
                container
                justifyContent="center"
                xs={12}
                sx={{ color: "text.primary", fontSize: 16 }}
              >
                <Grid item>
                  {(dividend * 1.72 * 0.22 * 1000).toLocaleString("en-US")}
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={4} alignContent="center">
              <Grid
                item
                container
                xs={12}
                sx={{ color: "text.secondary", fontSize: 12 }}
                justifyContent="center"
              >
                <Grid item>NETTO UTBYTTE ETTER SKATT</Grid>
              </Grid>
              <Grid
                item
                container
                justifyContent="center"
                xs={12}
                sx={{ color: "text.primary", fontSize: 16 }}
              >
                <Grid item>
                  {((dividend - dividend * 1.72 * 0.22) * 1000).toLocaleString(
                    "en-US"
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12} alignContent={"center"} p={2} mt={3}>
              <Grid
                item
                container
                xs={12}
                sx={{ color: "text.secondary", fontSize: 14 }}
                justifyContent="center"
              >
                <Grid item>
                  Beregn skatteeffekt av eventuelt tilleggsutbytte
                </Grid>
              </Grid>
              <Grid
                item
                container
                justifyContent="center"
                xs={12}
                sx={{ color: "text.primary", fontSize: 34 }}
              >
                <Grid item xs={12}>
                  <Slider
                    value={dividend}
                    onChange={(event, value) => {
                      if (Array.isArray(value)) return;
                      setDividend(value);
                    }}
                    marks={[
                      { value: 0, label: "0" },
                      { value: 10000, label: "10M" },
                      { value: 20000, label: "20M" },
                      { value: 30000, label: "30M" },
                      { value: 40000, label: "40M" },
                      { value: 50000, label: "50M" },
                    ]}
                    step={500}
                    /* marks */
                    min={0}
                    max={50000}
                    color="secondary"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </DialogWindow>
      <Grid item container spacing={2}>
        <Grid
          item
          container
          xs={12}
          justifyContent={"space-between"}
          spacing={2}
        >
          <Grid item>
            <Tooltip title="Kalkuler skatteeffekt av tilleggsutbytte">
              <IconButton
                onClick={() => {
                  setPopupMode("calculator");
                  setShowDetails(true);
                }}
              >
                <Calculate />
              </IconButton>
            </Tooltip>
            {/* <Button
                     variant="contained"
                     color="primary"
                     onClick={() => {
                        setPopupMode("calculator");
                        setShowDetails(true);
                     }}
                  >
                     BEREGN EFFEKT AV TILLEGGSUTBYTTE
                  </Button> */}
          </Grid>
          <Grid item>
            <Select
              sx={{ width: 120 }}
              size="small"
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value as number)}
            >
              <MenuItem value={2023}>2023</MenuItem>
              <MenuItem value={2024}>2024</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12} mt={3}>
        {loading ? (
          <CircularProgress size={40} />
        ) : (
          <Table
            size="small"
            sx={{
              ".MuiTableCell-head": {
                //color: "primary.contrastText",
                backgroundColor: "#eceff1",
                fontWeight: "bold",
              },
              "& th": {
                fontSize: 11,
              },
              "& td": {
                fontSize: 11,
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell width="50%">Type</TableCell>
                <TableCell width="25%" align="right">
                  Grunnlag
                </TableCell>
                <TableCell width="25%" align="right">
                  Beløp
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Formueskatt</TableCell>
                <TableCell align="right">
                  {Math.round(
                    wealthTax.reduce((value, item) => (value += item.amount), 0)
                  ).toLocaleString("en-US")}
                </TableCell>
                <TableCell align="right">
                  {Math.round(getWealthTaxAmount()).toLocaleString("en-US")}
                </TableCell>
              </TableRow>
              <TableRow
                onClick={() => {
                  setPopupMode("dividends");
                  setShowDetails((state) => !state);
                }}
                sx={{
                  "&: hover": {
                    cursor: "pointer",
                    "& td": {
                      backgroundColor: "primary.main",
                      color: "primary.contrastText",
                    },
                  },
                }}
              >
                <TableCell>Skatt på vedtatt utbytte</TableCell>
                <TableCell align="right">
                  {dividends
                    .reduce((value, item) => (value += item.amount), 0)
                    .toLocaleString("en-US")}
                </TableCell>
                <TableCell align="right">
                  {(
                    dividends.reduce(
                      (value, item) => (value += item.amount),
                      0
                    ) *
                    1.72 *
                    0.22
                  ).toLocaleString("en-US")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Skatt på estimert tilleggsutbytte</TableCell>
                <TableCell align="right">
                  {(dividend * 1000).toLocaleString("en-US")}
                </TableCell>
                <TableCell align="right">
                  {(dividend * 1.72 * 0.22 * 1000).toLocaleString("en-US")}
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  "& td": {
                    fontWeight: "bold",
                    color: "black",
                    backgroundColor: "#eceff1",
                  },
                }}
              >
                <TableCell>Totalt</TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right">
                  {Math.round(
                    getWealthTaxAmount() +
                      dividends.reduce(
                        (value, item) => (value += item.amount),
                        0
                      ) *
                        1.72 *
                        0.22 +
                      dividend * 1000 * 1.72 * 0.22
                  ).toLocaleString("en-US")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Utskrevet forskuddsskatt</TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right">
                  {Math.round(
                    cashflows
                      .filter((item) => item.taxPrepayment === true)
                      .reduce((value, item) => (value += item.amount), 0)
                  ).toLocaleString("en-US")}
                </TableCell>
              </TableRow>
            </TableBody>
            <TableFooter>
              <TableRow
                sx={{
                  "& td": {
                    fontWeight: "bold",
                    color: "black",
                    backgroundColor: "#eceff1",
                  },
                }}
              >
                <TableCell>
                  {getWealthTaxAmount() +
                    dividends.reduce(
                      (value, item) => (value += item.amount),
                      0
                    ) *
                      1.72 *
                      0.22 +
                    dividend * 1000 * 1.72 * 0.22 +
                    totalPrepayments >
                  0
                    ? "Estimert restskatt"
                    : "Til gode"}
                </TableCell>
                <TableCell align="right" />
                <TableCell align="right">
                  {Math.round(
                    Math.abs(
                      getWealthTaxAmount() +
                        dividends.reduce(
                          (value, item) => (value += item.amount),
                          0
                        ) *
                          1.72 *
                          0.22 +
                        dividend * 1000 * 1.72 * 0.22 +
                        totalPrepayments
                    )
                  ).toLocaleString("en-US")}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        )}
      </Grid>
    </Grid>
  );
}
