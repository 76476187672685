import { DefaultAxiosPostRequest } from "./ApiBase";
import { Cashflow } from "./GetCashflows";

export async function AddCashflow(cashflow: Omit<Cashflow, "id">) : Promise<Cashflow> {
    try{
        let result = await DefaultAxiosPostRequest(`AddCashflow`, cashflow);
        return result.data as Cashflow;
    }
    catch(e){
        throw e;
    }
}

