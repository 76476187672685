/**
 * Represents a generic provider
 */
export interface Provider {
   init(): void;
}
const urlFallback = {
   domain: "http://localhost:7183/api",
   ap: "http://localhost:7183/api",
};
/**
   Provider to allow access to configuration urls
   */
export class UrlProvider implements Provider {
   private _defaultDomainUrl: string;
   private _defaultApUrl: string;
   private _overrideDomainUrl?: string;
   private _overrideApUrl?: string;
   constructor() {
      this._defaultApUrl = "";
      this._defaultDomainUrl = "";
      // Init variables
      this.init();
   }
   init() {
      // @ts-ignore
      this._defaultApUrl = process.env.REACT_APP_API_URL;
      // @ts-ignore
      this._defaultDomainUrl = process.env.REACT_APP_API_URL;
      if (!this._defaultDomainUrl || !this._defaultApUrl) {
         console.error("ERROR: Unable to load default domain url or ap url. Fallback urls will be used");
         // Load fallback urls
         this._defaultApUrl = urlFallback.ap;
         this._defaultDomainUrl = urlFallback.domain;
      }
      console.info(`Successfully loaded urls: ${this._defaultDomainUrl}`);
   }
   /**
    * Returns the url to use for reaching the domain server.
    * If there is an override set, it will return the override value
    */
   getDomainUrl(): string {
      return this._overrideDomainUrl || this._defaultDomainUrl;
   }
   /**
    * Sets an override value for the domain url
    * @param value
    */
   overrideDomainUrl(value: string) {
      this._overrideDomainUrl = value;
   }
   /**
    * Returns the url of the server for auth providing
    */
   getApUrl(): string {
      return this._overrideApUrl || this._defaultApUrl;
   }
   /**
    * Sets an override for the url of the auth provider
    * @param value
    */
   overrideApUrl(value: string) {
      this._overrideApUrl = value;
   }
}

/**
 * The url provider singleton instance manager. It simply provides only 1 instance for all the
 * application.
 */
export class UrlProviderInstanceManager {
   private static _instance?: UrlProvider;
   static getInstance(): UrlProvider {
      if (!UrlProviderInstanceManager._instance) UrlProviderInstanceManager._instance = new UrlProvider();
      return UrlProviderInstanceManager._instance;
   }
}
// Create an instance and assign it to a unique ad simple variable
export const urlProvider = UrlProviderInstanceManager.getInstance();
