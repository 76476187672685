import { DefaultAxiosGetRequest } from "./ApiBase";
import { AccountingTransactionDto } from "./GetAccountingTransactions";
import { TransactionQuery } from "./GetTrialBalance";

export async function GetEliminations(parameters: TransactionQuery) : Promise<AccountingTransactionDto[]> {
    try{
        let result = await DefaultAxiosGetRequest<AccountingTransactionDto[], TransactionQuery>(`GetEliminations`, parameters);
        return result.data;
    }
    catch(e){
        throw e;
    }
}

