import { DefaultAxiosGetRequest, DefaultAxiosPostRequest } from "./ApiBase";

type UpdateUserAccessQuery = {
    id: string;
    clients: string[];
}

export async function UpdateUserAccess(data: UpdateUserAccessQuery): Promise<string[]> {
    try {
       let result = await DefaultAxiosPostRequest<string[], UpdateUserAccessQuery>(`UpdateUserAccess`, data);
       return result.data;
    } catch (e) {
       throw e;
    }
 }