import axios, { AxiosResponse } from "axios";
import { urlProvider } from "./urlProvider";

export async function DefaultAxiosGetRequest<T, V>(url: string, parameters: V): Promise<AxiosResponse<T>> {
   try {
      const apiEndpoint = urlProvider.getDomainUrl();
      let urlParams = new URLSearchParams();

      if (!parameters) return axios.get<T>(apiEndpoint + url);

      for (const [key, value] of Object.entries(parameters as object)) {
         urlParams.append(key, value);
      }

      const params = "?" + urlParams.toString();
      return axios.get<T>(apiEndpoint + url + params);
   } catch (e) {
      console.log(e);
      throw e;
   }
}

export async function DefaultAxiosDownloadTrigger<T>(url: string, data?: T) {
   try {
      const apiEndpoint = urlProvider.getDomainUrl();
      const response = await axios.post<Blob>(apiEndpoint + url, data, { responseType: "blob" });
      downloadBlob(response.data, "Disbursements.pptx");
      //const blobUrl = window.URL.createObjectURL(blob.data);
      //window.open(blobUrl);
   } catch (e) {
      console.log(e);
      throw e;
   }
}

export async function DefaultAxiosPostRequest<T, V>(url: string, parameters: V): Promise<AxiosResponse<T>> {
   try {
      const apiEndpoint = urlProvider.getDomainUrl();
      if (!parameters) return axios.post<T>(apiEndpoint + url);
      return axios.post<T>(apiEndpoint + url, parameters);
   } catch (e) {
      console.log(e);
      throw e;
   }
}

function downloadBlob(blob: Blob, name?: string) {
   // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
   const blobUrl = URL.createObjectURL(blob);

   // Create a link element
   const link = document.createElement("a");

   // Set link's href to point to the Blob URL
   link.href = blobUrl;
   if (name) {
      link.download = name;
   }

   // Append link to the body
   document.body.appendChild(link);

   // Dispatch click event on the link
   // This is necessary as link.click() does not work on the latest firefox
   link.dispatchEvent(
      new MouseEvent("click", {
         bubbles: true,
         cancelable: true,
         view: window,
      })
   );

   // Remove link from body
   document.body.removeChild(link);
}
