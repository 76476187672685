import { DefaultAxiosGetRequest, DefaultAxiosPostRequest } from "./ApiBase";
import { Cashflow } from "./GetCashflows";
import { Dividend } from "./GetDividends";

export async function DeleteDividend(dividend: Dividend) : Promise<boolean> {
    try{
        let result = await DefaultAxiosGetRequest(`DeleteDividend`, { id: dividend.id});
        return true;
    }
    catch(e){
        return false;
    }
}