import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { ThemeOptions, ThemeProvider, createTheme } from "@mui/material/styles";
import React from "react";
import ReactDOM from "react-dom/client";
import {
  Link,
  Outlet,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import App from "./App";
import { msalInstance } from "./config/authConfig";
import { AuthContextProvider } from "./context/AuthContext";
import { DataContextProvider } from "./context/DataContext";
import "./index.css";
import Dashboard from "./pages/Dashboard";
import TrialBalance from "./pages/admin/TrialBalance";
import Financials from "./pages/financials/Financials";
import Assets from "./pages/grieg-foundation/Assets";
import Disbursements from "./pages/grieg-foundation/Disbursements.1";
import Paintings from "./pages/grieg-foundation/Paintings";
import LoginPage from "./pages/login-page/LoginPage";
import reportWebVitals from "./reportWebVitals";
import UserAccess from "./pages/admin/UserAccess";
import EventEditor from "./pages/admin/EventEditor";
import Dividends from "./pages/admin/Dividends";
import TodoList from "./pages/admin/TodoList";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const PURPLE_THEME = {
  mode: "light",
  primary: {
    main: "#000950",
  },
  secondary: {
    main: "#00695c",
  },
};

/* const INDIGO_THEME = {
  mode: "light",
  palette: {
    primary: deepPurple,
    secondary: {
      main: "#f44336",
    },
  },
}; */

/* const theme: ThemeOptions = createTheme(INDIGO_THEME); */
const theme: ThemeOptions = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#000950",
    },
    secondary: {
      main: "#00695c",
    },
  },
});

function NotFound() {
  return (
    <div style={{ marginLeft: 25 }}>
      <h1>Page Not Found</h1>
      <Link to="/">Go back to Home Page</Link>
    </div>
  );
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Dashboard />,
        errorElement: (
          <div>
            <p>Unable to fetch requested resource.</p>
          </div>
        ),
      },
      {
        path: "/financials",
        element: <Financials />,
        errorElement: (
          <div>
            <p>Unable to fetch requested resource.</p>
          </div>
        ),
      },
      /* {
            path: "/likviditet",
            element: <Liquidity />,
            errorElement: (
               <div>
                  <h4>An unexpected error occured</h4>
                  <p>Unable to fetch requested resource.</p>
               </div>
            ),
         }, */
      {
        path: "/grieg-foundation",
        element: (
          <div>
            <Outlet />
          </div>
        ),
        children: [
          {
            path: "midler",
            element: <Assets />,
          },
          {
            path: "utdelinger",
            element: <Disbursements />,
          },
          {
            path: "kunst",
            element: <Paintings />,
          },
        ],
        errorElement: (
          <div>
            <h4>An unexpected error occured</h4>
            <p>Unable to fetch requested resource.</p>
          </div>
        ),
      },
      {
        path: "/admin",
        element: (
          <div>
            <Outlet />
          </div>
        ),
        children: [
          {
            path: "user-access",
            element: <UserAccess />,
          },
          {
            path: "event-editor",
            element: <EventEditor />,
          },
          {
            path: "dividends",
            element: <Dividends />,
          },
          {
            path: "todo-list",
            element: <TodoList />,
          },
          {
            path: "trialbalance",
            element: <TrialBalance />,
          },
        ],
        errorElement: (
          <div>
            <h4>An unexpected error occured</h4>
            <p>Unable to fetch requested resource.</p>
          </div>
        ),
      },
      /* {
        path: "/saldobalanse",
        element: <TrialBalance />,
        errorElement: (
          <div>
            <h4>An unexpected error occured</h4>
            <p>Unable to fetch requested resource.</p>
          </div>
        ),
      }, */
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <ThemeProvider theme={theme}>
        <AuthContextProvider>
          <DataContextProvider>
            <AuthenticatedTemplate>
              <RouterProvider router={router} />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <LoginPage />
            </UnauthenticatedTemplate>
          </DataContextProvider>
        </AuthContextProvider>
      </ThemeProvider>
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
