import { DefaultAxiosGetRequest } from "./ApiBase";

export interface AccountingTransactionDto {
    accountId: string;
    accountName: string;
    accountType: string;
    transactionDate: string;
    year: number;
    month: number;
    description: string;
    amount: number;
    currencyAmount: number;
    vendor: string;
    currency: string;
    counterParty: string;
    project: string;
    entity: string;
}

export interface TransactionQuery {
    periodFrom: string;
    periodTo: string;
    accounts?: string[];
    clients: string[];
    includeProfitLoss?: boolean;
    includeBalanceSheet?: boolean;
}

export async function GetAccountingTransactions(parameters: TransactionQuery) : Promise<AccountingTransactionDto[]> {
    try{
        let result = await DefaultAxiosGetRequest<AccountingTransactionDto[], TransactionQuery>(`GetAccountingTransactions`, parameters);
        return result.data;
    }
    catch(e){
        throw e;
    }
}

