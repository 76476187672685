import { DefaultAxiosGetRequest } from "./ApiBase";

type GetUserAccessQuery = {
    id: string;
}

export async function GetUserAccess(parameters: GetUserAccessQuery): Promise<string[]> {
    try {
       let result = await DefaultAxiosGetRequest<string[], GetUserAccessQuery>(`GetUserAccess`, parameters);
       return result.data;
    } catch (e) {
       throw e;
    }
 }