import { CurrencyExchange, Download, PieChart } from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { orange, red, teal, yellow } from "@mui/material/colors";
import Box from "@mui/system/Box";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Bar, Chart, Pie, getElementAtEvent } from "react-chartjs-2";
import { CategoryDto, GetCategories } from "../../api/GetCategories";
import {
  Disbursement,
  GetDisbursementHistory,
  ProjectDto,
} from "../../api/GetDisbursementHistory";
import Textbox from "../../components/Textbox";
import { getDistinctObjectsByPropertyAndTransform } from "../../utils/getDistinctObjects";
import ProjectTable from "../ProjectTable";
import useTheme from "@mui/system/useTheme";
import DownloadDisbursementSlide from "../../api/DownloadDisbursementSlide";
import DownloadProfitLossPresentation, {
  profitLossTestData,
} from "../../api/DownloadProfitLossPresentation";

type SliderValues = {
  startingValue: number;
  endValue: number;
  min: number;
  max: number;
};

type CurrentSliderValues = {
  current: number;
  min: number;
  max: number;
};

type HistoricalPageProperties = {
  page: "timeline" | "pie-chart";
  cpiAdjusted: boolean;
};

export default function Disbursements() {
  const chartRef = useRef();
  const theme = useTheme();
  const isMobilePhone = useMediaQuery(theme.breakpoints.down("sm"));

  const [selectedProject, setSelectedProject] = useState<ProjectDto | null>(
    null
  );

  const [currentSliderValues, setCurrentSliderValues] =
    useState<CurrentSliderValues>({
      min: 2008,
      max: 2023,
      current: 2023,
    });

  const [sliderValues, setSliderValues] = useState<SliderValues>({
    min: 2008,
    max: 2023,
    startingValue: 2008,
    endValue: 2023,
  });

  const [pageProperties, setPageProperties] =
    useState<HistoricalPageProperties>({
      page: "timeline",
      cpiAdjusted: false,
    });

  function updatePageProperties(property: Partial<HistoricalPageProperties>) {
    setPageProperties((state) => {
      let newState = { ...state, ...property };
      return newState;
    });
  }
  const [disbursements, setDisbursements] = useState<Disbursement[]>([]);
  const [filteredDisbursements, setFilteredDisbursements] = useState<
    Disbursement[]
  >([]);

  const barOptions = {
    plugins: {
      title: {
        display: false,
        text: "",
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        color: "white",
        backgroundColor: "black",
        formatter: function (value: any, context: any) {
          return Math.round(value / 1000).toLocaleString("en-US");
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  const [barData, setBarData] = useState<any>();

  const [selectedCategory, setSelectedCategory] = useState<Disbursement | null>(
    null
  );

  const [categories, setCategories] = useState<CategoryDto[]>([]);

  function getYears(): number[] {
    return getDistinctObjectsByPropertyAndTransform(
      disbursements,
      "year",
      (c) => c.year
    ).sort();
  }

  const downloadDisbursementSlide = () => {
    const categories = disbursements
      .filter((item) => item.year === currentSliderValues.current)
      .map((item) => ({ category: item.category, disbursement: 0, budget: 0 }));

    categories.forEach((cat) => {
      cat.disbursement = disbursements
        .filter(
          (item) =>
            item.year === currentSliderValues.current &&
            item.category === cat.category
        )
        .reduce(
          (value, cat2) =>
            (value += cat2.projects.reduce(
              (value2, item) => (value2 += item.amount),
              0
            )),
          0
        );

      cat.budget = disbursements
        .filter(
          (item) =>
            item.year === currentSliderValues.current &&
            item.category === cat.category
        )
        .reduce((value, cat2) => (value += cat2.budget), 0);
    });

    DownloadDisbursementSlide(categories, currentSliderValues.current);
  };

  useEffect(() => {
    getData();
    async function getData() {
      try {
        let [disbursements, categories] = await Promise.all([
          GetDisbursementHistory(),
          GetCategories(),
        ]);

        setDisbursements(disbursements);
        setCategories(categories);
      } catch (e) {
        alert(e);
      }
    }
  }, []);

  useEffect(() => {
    try {
      setFilteredDisbursements(
        disbursements.filter(
          (item) =>
            item.year >= sliderValues.startingValue &&
            item.year <= sliderValues.endValue
        )
      );
    } catch (e) {
      alert(e);
    }
  }, [sliderValues, disbursements]);

  useEffect(() => {
    let years = getYears();
    if (years.length > 0) {
      setSliderValues({
        min: years[0],
        max: years[years.length - 1],
        startingValue: years[0],
        endValue: years[years.length - 1],
      });
      setCurrentSliderValues({
        min: years[0],
        max: years[years.length - 1],
        current: years[years.length - 1],
      });
    }
  }, [disbursements]);

  const [mode, setMode] = useState<"current" | "historical">("current");
  const [historicalMode, setHistoricalMode] = useState<
    "timeline" | "pie-chart"
  >("timeline");

  useEffect(() => {
    try {
      setBarData({
        labels: disbursements
          .filter((item) => item.year === currentSliderValues.current)
          .map((item) => item.category),
        datasets: [
          {
            label: "Utdelt",
            data: disbursements
              .filter((item) => item.year === currentSliderValues.current)
              .map((item) =>
                item.projects.reduce((value, item) => (value += item.amount), 0)
              ),
            backgroundColor: (item: any, context: any) => {
              let currentItem = disbursements.filter(
                (item) => item.year === currentSliderValues.current
              )[item.dataIndex];
              if (
                currentItem &&
                currentItem.category === selectedCategory?.category
              )
                return red[800];
              return teal[900];
            },
          },
          {
            label: "Tildelt",
            backgroundColor: (item: any, context: any) => {
              let currentItem = disbursements.filter(
                (item) => item.year === currentSliderValues.current
              )[item.dataIndex];
              if (
                currentItem &&
                currentItem.categoryId === selectedCategory?.categoryId
              )
                return red[100];
              return teal[100];
            },
            data: disbursements
              .filter((item) => item.year === currentSliderValues.current)
              .map((item, index) => {
                return item.budget;
              }),
          },
        ],
      });
    } catch (e) {
      throw e;
    } finally {
    }
  }, [disbursements, selectedCategory, currentSliderValues.current]);

  function onSliderValueChange(event: Event, newValue: number | number[]) {
    if (Array.isArray(newValue) && newValue.length > 1) {
      setSliderValues((state) => {
        let newItem = { ...state };
        newItem.startingValue = newValue[0];
        newItem.endValue = newValue[1];
        return newItem;
      });
    } else {
      setCurrentSliderValues((state) => {
        if (!Array.isArray(newValue)) {
          let newItem = { ...state, current: newValue };
          return newItem;
        }
        return state;
      });
    }
  }

  function onBarChartClick(
    event: React.MouseEvent<HTMLCanvasElement, MouseEvent>
  ) {
    if (!chartRef.current) return;
    let selectedElement = getElementAtEvent(chartRef.current, event);
    if (selectedElement && selectedElement.length > 0) {
      let item = disbursements.filter(
        (item) => item.year === currentSliderValues.current
      )[selectedElement[0].index];
      setSelectedCategory((state) => {
        if (state?.category === item.category) return null;
        return item;
      });
    }
  }

  return (
    <Grid item container xs={12} alignContent="center" spacing={2}>
      <Grid
        item
        container
        xs={12}
        spacing={2}
        sx={{
          borderBottom: "1px solid lightGrey",
          ml: 1,
          mr: 1,
          pt: 1,
          pb: 1,
        }}
      >
        <Grid item container xs={12} md={4} spacing={1}>
          <Grid item xs={9}>
            <ButtonGroup>
              <Button
                onClick={() => setMode("current")}
                variant={mode === "current" ? "contained" : "outlined"}
                size="small"
                sx={{ width: 150, fontSize: 10, height: 30 }}
              >
                Inneværende år
              </Button>
              <Button
                onClick={() => setMode("historical")}
                variant={mode === "historical" ? "contained" : "outlined"}
                size="small"
                sx={{ width: 150, fontSize: 10, height: 30 }}
              >
                Historisk
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item xs={3}>
            <IconButton onClick={() => downloadDisbursementSlide()}>
              <Download />
            </IconButton>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          container
          justifyContent={isMobilePhone ? "flex-start" : "flex-end"}
        >
          <Grid item sx={{ width: isMobilePhone ? 300 : 500 }}>
            <Box>
              <Typography gutterBottom>Vis år</Typography>
              <Slider
                min={sliderValues.min}
                max={sliderValues.max}
                marks={[
                  { value: sliderValues.min, label: sliderValues.min },
                  { value: sliderValues.max, label: sliderValues.max },
                ]}
                value={
                  mode === "historical"
                    ? [sliderValues.startingValue, sliderValues.endValue]
                    : currentSliderValues.current
                }
                onChange={onSliderValueChange}
                valueLabelDisplay="auto"
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={mode === "current"}>
          <Grid
            item
            container
            xs={12}
            spacing={3}
            sx={{ pt: 1, pb: 1 }}
            justifyContent="center"
          >
            <Grid item xs={12} sm={6} md={4}>
              <Textbox
                title={`Tildelingsramme for ${currentSliderValues.current}`}
                hightlightedText={disbursements
                  .filter((item) => item.year === currentSliderValues.current)
                  .reduce((value, item) => (value += item.budget), 0)
                  .toLocaleString("en-US")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Textbox
                title="Godkjente prosjekter"
                hightlightedText={disbursements
                  .filter((item) => item.year === currentSliderValues.current)
                  .reduce(
                    (value, item) =>
                      (value += item.projects.reduce(
                        (value2, item2) => (value2 += item2.amount),
                        0
                      )),
                    0
                  )
                  .toLocaleString("en-US")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Textbox
                title="Utbetalt så langt"
                hightlightedText={Math.round(
                  disbursements
                    .filter((item) => item.year === currentSliderValues.current)
                    .reduce(
                      (value, item) =>
                        (value += item.projects.reduce(
                          (value2, item2) => (value2 += item2.amount),
                          0
                        )),
                      0
                    ) * 0.9
                ).toLocaleString("en-US")}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                {barData && (
                  <Card>
                    <CardContent>
                      <Grid item xs={12}>
                        <Chart
                          type="bar"
                          height={isMobilePhone ? 200 : 250}
                          ref={chartRef}
                          data={barData}
                          options={barOptions}
                          onClick={onBarChartClick}
                        />
                      </Grid>
                    </CardContent>
                  </Card>
                )}
              </Grid>
              <Grid item xs={12}>
                <Collapse in={selectedCategory !== null}>
                  <ProjectTable category={selectedCategory} />
                </Collapse>
              </Grid>
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
      <Grid item container xs={12}>
        <Collapse in={mode === "historical"}>
          <Card>
            <CardContent>
              <HistoricalProjects
                disbursements={filteredDisbursements}
                categories={categories}
                properties={pageProperties}
                updateProperty={updatePageProperties}
              />
            </CardContent>
          </Card>
        </Collapse>
      </Grid>
    </Grid>
  );

  type HistoricalProjectsProps = {
    disbursements: Disbursement[];
    categories: CategoryDto[];
    properties: HistoricalPageProperties;
    updateProperty: (property: Partial<HistoricalPageProperties>) => void;
  };

  type Category = {
    name: string;
    amount: number;
  };

  function HistoricalProjects({
    disbursements,
    categories,
    properties,
    updateProperty,
  }: HistoricalProjectsProps) {
    const theme = useTheme();
    const isMobilePhone = useMediaQuery(theme.breakpoints.down("sm"));

    const chartRef = useRef();
    const barChartRef = useRef();
    const [barData, setBarData] = useState<any>({ labels: [], datasets: [] });
    const [barChart, setBarChart] = useState({
      plugins: {
        title: {
          display: false,
          text: "",
        },
        legend: {
          display: true,
          position: "bottom",
        },
        datalabels: {
          display: false,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
          min: 0,
          max: 160000000,
          ticks: {
            stepSize: 20000000,
          },
        },
      },
    });

    const [selectedCategory, setSelectedCategory] =
      useState<CategoryDto | null>(null);
    const [selectedItems, setSelectedItems] = useState<Disbursement[] | null>(
      null
    );

    const [selectedYear, setSelectedYear] = useState<number | null>(null);

    function onPieChartClick(
      event: React.MouseEvent<HTMLCanvasElement, MouseEvent>
    ) {
      if (!chartRef.current) return;
      if (getElementAtEvent(chartRef.current, event).length === 0) return;
      let selectedElement =
        categories[getElementAtEvent(chartRef.current, event)[0].index];

      setSelectedCategory((state) => {
        if (state === null) return selectedElement;
        if (state.id === selectedElement.id) return null;
        return selectedElement;
      });
    }

    function onBarChartClick(
      event: React.MouseEvent<HTMLCanvasElement, MouseEvent>
    ) {
      if (!barChartRef.current) return;
      if (getElementAtEvent(barChartRef.current, event).length === 0) return;

      let element = getElementAtEvent(barChartRef.current, event)[0];
      let selectedCategory = categories.find(
        (item) => item.name === getCategories()[element.datasetIndex]
      );
      let year = getYears()[element.index];
      if (selectedCategory === undefined || !year) return;

      setSelectedCategory((state) => {
        if (state === null && selectedCategory !== undefined)
          return selectedCategory;
        if (
          (state !== null &&
            state.id === selectedCategory?.id &&
            year === selectedYear) ||
          selectedCategory === undefined
        )
          return null;
        return selectedCategory;
      });

      setSelectedYear(year);
    }

    useEffect(() => {
      setSelectedYear(null);
    }, [mode]);

    useEffect(() => {
      if (selectedCategory === null) {
        setSelectedItems(null);
        return;
      }

      if (selectedYear === null) {
        setSelectedItems(
          disbursements.filter(
            (item) => item.categoryId === selectedCategory.id
          )
        );
        return;
      }

      setSelectedItems(
        disbursements.filter(
          (item) =>
            item.categoryId === selectedCategory.id &&
            item.year === selectedYear
        )
      );
    }, [selectedCategory, selectedYear]);

    useEffect(() => {
      if (disbursements.length > 0) {
        setBarData({
          labels: getYears().map((year) => {
            return year;
          }),
          datasets: getCategories().map((item) => ({
            label: item,
            data: getCategoryAmountsByYear(item),
            backgroundColor: (item2: any, context: any) => {
              return getBackgroundColor(item, getYears()[item2.index]);
            },
          })),
        });

        let chartOptions = { ...barChart };
        chartOptions.scales.y.max = getMaximumChartPoint(disbursements);

        setBarChart(chartOptions);
      }
    }, [disbursements, properties.cpiAdjusted, selectedCategory, selectedYear]);

    function getMaximumChartPoint(disbursements: Disbursement[]): number {
      let max = getYears().reduce((value, year) => {
        const total = disbursements
          .filter((disb) => disb.year === year)
          .reduce(
            (value2, item2) =>
              (value2 += item2.projects.reduce(
                (value3, item3) => (value3 += item3.cpiAdjustedAmount),
                0
              )),
            0
          );

        if (total > value) {
          return total;
        }
        return value;
      }, 0);

      return roundToNearest(max, 20000000);
    }

    function roundToNearest(value: number, nearest: number) {
      let amount = Math.ceil(value / nearest) * nearest;
      return amount;
    }

    function getYears(): number[] {
      return getDistinctObjectsByPropertyAndTransform(
        disbursements,
        "year",
        (c) => c.year
      ).sort();
    }

    function getCategories(): string[] {
      return getDistinctObjectsByPropertyAndTransform(
        disbursements,
        "category",
        (c) => c.category
      ).sort();
    }

    function getCategoryAmountsByYear(category: string) {
      let amounts = getYears().map((year) => {
        let amount = disbursements
          .filter((item) => item.category === category && item.year === year)
          .reduce(
            (val, item) =>
              (val += item.projects.reduce(
                (val2, item2) =>
                  (val2 += properties.cpiAdjusted
                    ? item2.cpiAdjustedAmount
                    : item2.amount),
                0
              )),
            0
          );
        return Math.round(amount);
      });

      return amounts;
    }

    function getBackgroundColor(category: string, year: number | null = null) {
      if (selectedCategory?.name === category && selectedYear === year)
        return red[500];

      switch (category) {
        case "Children and Youth":
          return teal[700];
        case "Climate Action":
          return teal[500];
        case "Health and Research":
          return teal[300];
        case "Music and Culture":
          return teal[100];
        case "Art":
          return yellow[100];
        case "Social Impact Investment":
          return orange[100];
        default:
          return undefined;
      }
    }

    function getTotalForYear(year: number) {
      let amount = disbursements
        .filter((item) => item.year === year)
        .reduce(
          (val, item) =>
            (val += item.projects.reduce(
              (val2, item2) =>
                (val2 += properties.cpiAdjusted
                  ? item2.cpiAdjustedAmount
                  : item2.amount),
              0
            )),
          0
        );
      return amount;
    }

    function getTotal(category: string) {
      let amount = disbursements
        .filter((item) => item.category === category)
        .reduce(
          (val, item) =>
            (val += item.projects.reduce(
              (val2, item2) =>
                (val2 += properties.cpiAdjusted
                  ? item2.cpiAdjustedAmount
                  : item2.amount),
              0
            )),
          0
        );
      return amount;
    }

    function getTotalForYearAndCategory(category: string, year: number) {
      let amount = disbursements
        .filter((item) => item.category === category && item.year === year)
        .reduce(
          (val, item) =>
            (val += item.projects.reduce(
              (val2, item2) =>
                (val2 += properties.cpiAdjusted
                  ? item2.cpiAdjustedAmount
                  : item2.amount),
              0
            )),
          0
        );
      return amount;
    }

    function getTotalForAllYears() {
      let amount = disbursements.reduce(
        (val, item) =>
          (val += item.projects.reduce(
            (val2, item2) =>
              (val2 += properties.cpiAdjusted
                ? item2.cpiAdjustedAmount
                : item2.amount),
            0
          )),
        0
      );
      return amount;
    }

    const [pieChartData, setPieChartData] = useState<any>({
      labels: categories
        .filter((item) => getTotal(item.name) > 0)
        .map((item) => item.name),
      datasets: [
        {
          label: "",
          data: categories
            .filter((item) => getTotal(item.name) > 0)
            .map((item) => getTotal(item.name)),
          backgroundColor: categories.map((item, index) =>
            getPieChartColors(index)
          ),
          /* borderColor: [teal[800], teal[800], teal[800], teal[800]], */
          borderWidth: 1,
        },
      ],
    });

    function getPieChartColors(index: number) {
      const colors = [
        teal[700],
        teal[500],
        teal[300],
        teal[100],
        yellow[100],
        orange[100],
        red[100],
      ];

      if (index >= 0 && index <= 6) return colors[index];
      return colors[0];
    }

    useEffect(() => {
      setPieChartData({
        labels: categories
          .filter((item) => getTotal(item.name) > 0)
          .map((item) => item.name),
        datasets: [
          {
            label: "",
            data: categories
              .filter((item) => getTotal(item.name) > 0)
              .map((item) => getTotal(item.name)),
            backgroundColor: (item: any, context: any) => {
              if (item.dataIndex == undefined) return;
              let category = categories[item.dataIndex];
              if (!selectedCategory) return getPieChartColors(item.dataIndex);
              if (selectedCategory.id == category?.id) return red[500];
              return getPieChartColors(item.dataIndex);
            },
            /* borderColor: [teal[800], teal[800], teal[800], teal[800]], */
            borderWidth: 1,
          },
        ],
      });
    }, [properties.cpiAdjusted, selectedCategory]);

    return (
      <>
        <Grid item xs={12} container spacing={2}>
          <Grid
            item
            container
            xs={12}
            md={1}
            spacing={1}
            sx={{
              borderRight: isMobilePhone ? undefined : "1px solid lightgrey",
            }}
          >
            <Grid
              item
              xs={12}
              container
              spacing={1}
              justifyContent={"flex-start"}
              alignItems={"center"}
              direction={isMobilePhone ? "row" : "column"}
            >
              <Grid item>
                <Tooltip title="Vis KPI-justert beløp til dagens kroneverdi">
                  <IconButton
                    onClick={() =>
                      updateProperty({
                        cpiAdjusted: !properties.cpiAdjusted,
                      })
                    }
                  >
                    <CurrencyExchange
                      sx={
                        properties.cpiAdjusted ? { color: red[500] } : undefined
                      }
                    />
                  </IconButton>
                </Tooltip>
              </Grid>

              <Grid item>
                <Tooltip title="Vis historiske utdelinger per kategori">
                  <IconButton
                    onClick={() =>
                      updateProperty({
                        page:
                          properties.page === "pie-chart"
                            ? "timeline"
                            : "pie-chart",
                      })
                    }
                  >
                    <PieChart
                      sx={
                        properties.page === "pie-chart"
                          ? { color: red[500] }
                          : undefined
                      }
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={11}>
            <Grid item xs={12}>
              <Collapse in={properties.page === "pie-chart"}>
                <Grid item container xs={12} spacing={3}>
                  <Grid item xs={12} md={3}>
                    <Card sx={{ minHeight: "100%" }}>
                      <CardContent sx={{ height: "100%" }}>
                        <Grid
                          item
                          container
                          xs={12}
                          alignContent="space-between"
                        >
                          <Grid item xs={12}>
                            <Textbox
                              title="Totale midler utdelt"
                              hightlightedText={Math.round(
                                getTotalForAllYears()
                              ).toLocaleString("en-US")}
                            />
                          </Grid>
                          <Grid item xs={12} sx={{ paddingTop: 10 }}>
                            <TableContainer>
                              <Table
                                size="small"
                                sx={{
                                  ".MuiTableCell-head": {
                                    fontWeight: "bold",
                                  },
                                  "& th": {
                                    fontSize: 10,
                                  },
                                  "& td": {
                                    fontSize: 10,
                                  },
                                }}
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Kategori</TableCell>
                                    <TableCell align="right">Beløp</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {getCategories().map((category) => (
                                    <TableRow>
                                      <TableCell>{category}</TableCell>

                                      <TableCell align="right">
                                        {Math.round(
                                          getTotal(category)
                                        ).toLocaleString("en-US")}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                  <TableRow
                                    sx={{
                                      "& td": {
                                        fontWeight: "bold",
                                        backgroundColor: "lightgrey",
                                      },
                                    }}
                                  >
                                    <TableCell>Total</TableCell>

                                    <TableCell align="right">
                                      {Math.round(
                                        getTotalForAllYears()
                                      ).toLocaleString("en-US")}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    {pieChartData && (
                      <Pie
                        ref={chartRef}
                        data={pieChartData}
                        height={300}
                        onClick={onPieChartClick}
                        options={{
                          maintainAspectRatio: false,
                          plugins: {
                            legend: {
                              display: false,
                              position: "right",
                            },
                            datalabels: {
                              display: true,
                              anchor: "center",
                              color: "white",
                              backgroundColor: "black",
                              formatter: function (value: any, context: any) {
                                if (context && context.chart.data.labels)
                                  return context.chart.data.labels[
                                    context.dataIndex
                                  ];
                                return value;
                              },
                            },
                          },
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
            <Grid item container xs={12}>
              <Collapse in={properties.page === "timeline"}>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <Bar
                      onClick={onBarChartClick}
                      ref={barChartRef}
                      data={barData}
                      options={barChart as any}
                      height={300}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TableContainer sx={{ width: "75vw" }}>
                      <Table
                        size="small"
                        sx={{
                          ".MuiTableCell-head": {
                            fontWeight: "bold",
                          },
                          "& th": {
                            fontSize: 10,
                          },
                          "& td": {
                            fontSize: 10,
                          },
                        }}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell
                              align="center"
                              colSpan={getYears().length}
                            >
                              År
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Kategori</TableCell>
                            {getYears().map((item: number, index: number) => (
                              <TableCell align="right">{item}</TableCell>
                            ))}
                            <TableCell
                              align="right"
                              sx={{ backgroundColor: "lightgray" }}
                            >
                              Totalt
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {getCategories().map((category) => (
                            <TableRow>
                              <TableCell>{category}</TableCell>
                              {getYears().map(
                                (year: number, index: number, ber) => (
                                  <TableCell align="right">
                                    {Math.round(
                                      getTotalForYearAndCategory(category, year)
                                    ).toLocaleString("en-US")}
                                  </TableCell>
                                )
                              )}
                              <TableCell
                                align="right"
                                sx={{
                                  backgroundColor: "lightgrey",
                                  fontWeight: "bold",
                                }}
                              >
                                {Math.round(getTotal(category)).toLocaleString(
                                  "en-US"
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                          <TableRow
                            sx={{
                              "& td": {
                                fontWeight: "bold",
                                backgroundColor: "lightgrey",
                              },
                            }}
                          >
                            <TableCell>Total</TableCell>
                            {getYears().map((year, index) => (
                              <TableCell align="right">
                                {Math.round(
                                  getTotalForYear(year)
                                ).toLocaleString("en-US")}
                              </TableCell>
                            ))}
                            <TableCell align="right">
                              {Math.round(getTotalForAllYears()).toLocaleString(
                                "en-US"
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={selectedItems !== null}>
            <ProjectTable
              category={selectedItems}
              cpiAdjustProjects={properties.cpiAdjusted}
            />
          </Collapse>
        </Grid>
      </>
    );
  }
}
