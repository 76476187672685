import { DefaultAxiosGetRequest } from "./ApiBase";

export interface User {
   userId: string;
   email: string;
   name: string;
}

export async function GetUsers(): Promise<User[]> {
   try {
      let result = await DefaultAxiosGetRequest(`GetUsers`, undefined);
      return result.data as User[];
   } catch (e) {
      throw e;
   }
}
