import {
  Autocomplete,
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Moment } from "moment";
import React from "react";
import { useState } from "react";

export default function TodoList() {
  const [date, setDate] = useState<Moment | null>(null);

  const onChangeDate = (newValue: Moment | null) => setDate(newValue);

  return (
    <Card>
      <CardContent>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              value={"Gry Larsen"}
              sx={{
                maxWidth: 400,
                width: "90%",
                backgroundColor: "primary.contrastText",
                ".MuiInputBase-root": { fontSize: 12 },
              }}
              size="small"
              onChange={(event, value) => undefined}
              title="Bruker"
              options={["Gry Larsen"]}
              renderInput={(params) => (
                <TextField
                  sx={{
                    fontSize: 11,
                  }}
                  {...params}
                  InputLabelProps={{ sx: { fontSize: 11 } }}
                  label="Klient"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                sx={{ width: "100%" }}
                label="Dato"
                value={date}
                onChange={onChangeDate}
                format={"DD/MM/YYYY"}
              ></DatePicker>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Beskrivelse"
              value={""}
              onChange={(e) => "undefined"}
            />
          </Grid>
          <Grid item xs={12}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Sak</TableCell>
                  <TableCell>Eier</TableCell>
                  <TableCell>Opprettet</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Ferdigstillelse</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Årsrapport</TableCell>
                  <TableCell>Fredrik Østervold</TableCell>
                  <TableCell>5.1.2024</TableCell>
                  <TableCell>
                    Ferdigstilt avslutning av årsregnskapet for de indivuelle
                    selskapene - påbegynt konsolidering.
                  </TableCell>
                  <TableCell>20%</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <AlignItemsList />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sx={{ mt: 10 }}>
          <HorizontalLinearAlternativeLabelStepper />
        </Grid>
      </CardContent>
    </Card>
  );
}

function AlignItemsList() {
  return (
    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Fredrik Østervold" src="">
            F
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="4.1.2024 - Ferdigstlit Grieg Maturitas AS"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Fredrik Østervold
              </Typography>
              {" — Gjenstår kun GGR av toppselskapene"}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Fredrik Østervold" src="">
            F
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="12.2.2024 - Startet konsolideringsprosessen"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Fredrik Østervolld
              </Typography>
              {
                " — Mats hos KPMG har påbegynt konsolidering, estimert ferdigstillelse 14.2."
              }
            </React.Fragment>
          }
        />
      </ListItem>
    </List>
  );
}

const steps = [
  "Ferdigstille regnskap for underliggende selskaper",
  "Konsolidere tall",
  "Lage notegrunnlag",
  "Lage øvrige tekster",
  "Lage endelig årsrapport",
];

function HorizontalLinearAlternativeLabelStepper() {
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={1} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
