import { DefaultAxiosGetRequest } from "./ApiBase";
import { GetEventsQuery } from "./GetCashflows";

export interface Dividend {
    id: number;
    date: string;
    client: string;
    amount: number;
    description: string | null;
}

export async function GetDividends(parameters: GetEventsQuery) : Promise<Dividend[]> {
    try{
        let result = await DefaultAxiosGetRequest(`GetDividends`, parameters);
        return result.data as Dividend[];
    }
    catch(e){
        throw e;
    }
}