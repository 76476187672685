import {
  Card,
  CardContent,
  CircularProgress,
  Fade,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { GetPaintings } from "../../api/GetPaintings";
import Textbox from "../../components/Textbox";
import useTheme from "@mui/system/useTheme";

export interface PaintingDto {
  id: number;
  name: string | null;
  artist: string;
  technique: string;
  size: string;
  value: number;
  acquiredYear: number;
}

type PaintingsProps = {};

export default function Paintings() {
  const [paintings, setPaintings] = useState<PaintingDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const theme = useTheme();
  const isMobilePhone = useMediaQuery(theme.breakpoints.down("sm"));

  let columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Navn",
      flex: 1,
      editable: false,
      sortable: true,
    },
    {
      field: "artist",
      headerName: "Artist",
      flex: 1,
      editable: false,
      sortable: true,
    },
  ];

  if (!isMobilePhone) {
    columns = [
      ...columns,

      {
        field: "technique",
        headerName: "Teknikk",
        flex: 1,
        editable: false,
        sortable: true,
      },
      {
        field: "acquiredYear",
        headerName: "År",
        sortable: true,
        flex: 0.3,
      },
    ];
  }

  columns.push({
    field: "value",
    headerName: "Kostpris",
    sortable: true,
    width: 75,
    type: "number",
  });

  useEffect(() => {
    getData();
    async function getData() {
      try {
        setLoading(true);
        let data = await GetPaintings();
        setPaintings(data.sort((a, b) => b.value - a.value));
      } catch (e) {
      } finally {
        setLoading(false);
      }
    }
  }, []);

  return (
    <Grid item container xs={12} spacing={3}>
      {loading ? (
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          alignItems={"center"}
          minHeight={"50vh"}
        >
          <CircularProgress size={100} />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Grid item container xs={12} spacing={3}>
            <Grid item xs={12} sm={6}>
              <Textbox
                title="Antall kunstverk"
                hightlightedText={paintings.length.toString()}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Textbox
                title="Total verdi av samling"
                hightlightedText={paintings
                  .reduce((value, item) => (value += item.value), 0)
                  .toLocaleString("en-US")}
              />
            </Grid>
            <Grid item container xs={12}>
              {paintings.length > 0 ? (
                <Card sx={{ width: "100%" }}>
                  <Grid item xs={12}>
                    <CardContent>
                      <DataGrid
                        sx={{
                          fontSize: 12,
                          "& .MuiDataGrid-columnHeaderTitle": {
                            fontWeight: "bold",
                          },
                        }}
                        columns={columns}
                        rows={paintings}
                        density="compact"
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: isMobilePhone ? 10 : 25,
                            },
                          },
                        }}
                        pageSizeOptions={[10, 25, 40]}
                      />
                    </CardContent>
                  </Grid>
                </Card>
              ) : (
                <h4>Ingen kunstverk tilgjengelig i databasen.</h4>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
