import { DefaultAxiosGetRequest } from "./ApiBase";

export interface CategoryDto {
    id: number;
    name: string;
}

export async function GetCategories() : Promise<CategoryDto[]> {
    try{
        let result = await DefaultAxiosGetRequest(`GetCategories`, undefined);
        return result.data as CategoryDto[];
    }
    catch(e){
        throw e;
    }
}