import { useTheme } from "@mui/material";
import { green, red } from "@mui/material/colors";
import "chart.js/auto";
import { useEffect, useState } from "react";
import { Chart } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

/* ChartJS.register(ChartDataLabels); */

type Value = {
   name: string;
   value: number;
};
type WaterfallChartProps = {
   options: WaterfallChartOptions;
   values: Value[];
};

type WaterfallChartOptions = {
   startingValue?: number;
   startingLabel?: string;
   endLabel?: string;
   direction?: "horizontal" | "vertical";
};

export default function WaterfallChart({ options, values }: WaterfallChartProps) {
   const [labels, setLabels] = useState<string[]>([]);
   const theme = useTheme();

   const barPercentage = options.direction === "horizontal" ? 0.75 : 0.9;

   useEffect(() => {
      setLabels([
         options.startingLabel ?? "Inngående balanse",
         ...values.map((item) => item.name),
         options.endLabel ?? "Sluttbalanse",
      ]);
   }, [values]);

   function valueIsNegative(value: number) {
      return value < 0;
   }

   function getMaximumPoint() {
      return values.reduce((value, item) => {
         let newValue = value + item.value;
         if (newValue > value) return newValue;
         return value;
      }, options.startingValue ?? 0);
   }

   function roundToNearest(value: number, nearest: number) {
      let amount = Math.ceil(value / nearest) * nearest;
      return amount;
   }

   const barOptions = {
      indexAxis: options.direction === "horizontal" ? ("y" as const) : ("x" as const),
      elements: {
         bar: {},
      },
      plugins: {
         title: {
            display: false,
         },
         legend: {
            display: false,
         },
         /* datalabels: {
            display: false,
         }, */
         tooltip: {
            filter: (item: any, data: any) => {
               if (item.datasetIndex === 0) return false;
               return true;
            },
            callbacks: {
               label: (item: any) => {
                  if (item.dataIndex === 0) return options.startingValue?.toLocaleString("en-US") ?? "0";
                  if (item.dataIndex === labels.length - 1)
                     return (
                        (options.startingValue ?? 0) + values.reduce((value, item) => (value += item.value), 0)
                     ).toLocaleString("en-US");
                  return values[item.dataIndex - 1].value.toLocaleString("en-US");
               },
            },
         },
      },
      maintainAspectRatio: false,
      responsive: true,
      scales: {
         x: {
            stacked: true,
            max: roundToNearest(getMaximumPoint() * 2, getMaximumPoint() < 1000000 ? 500000 : 2500000),
            ticks: {
               display: options.direction === "horizontal" ? false : true,
               font: {
                  size: 11,
               },
            },
         },
         y: {
            stacked: true,
            max: roundToNearest(getMaximumPoint() * 2, getMaximumPoint() < 1000000 ? 500000 : 2500000),
            ticks: {
               padding: 1,
               font: {
                  size: 9,
               },
            },
         },
      },
   };

   function createHiddenDataset() {
      let data: number[] = [];
      let startingPoint = options.startingValue ? options.startingValue : 0;
      data.push(0);
      values.forEach((item) => {
         let newValue = startingPoint + item.value;
         if (newValue < 0 || (newValue > 0 && startingPoint < 0)) {
            data.push(0);
            startingPoint = newValue;
            return;
         }

         if (valueIsNegative(item.value)) {
            startingPoint += item.value;
            data.push(startingPoint);
         } else {
            data.push(startingPoint);
            startingPoint += item.value;
         }
      });
      data.push(0);
      return data;
   }

   function createBarDataset() {
      let data: number[] = [];
      let startingValue = options.startingValue ?? 0;
      data.push(startingValue);
      values.forEach((item) => {
         if (startingValue > 0 && startingValue + item.value < 0) {
            data.push(startingValue);
            startingValue += item.value;
            return;
         }
         if (startingValue < 0 && startingValue + item.value > 0) {
            data.push(startingValue + item.value);
            startingValue += item.value;
            return;
         }

         data.push(Math.abs(item.value));
         startingValue += item.value;
      });
      data.push((options.startingValue ?? 0) + values.reduce((value, item) => (value += item.value), 0));
      return data;
   }

   function createNegativePositiveDataset() {
      let data: number[] = [];
      let startingValue = options.startingValue ?? 0;
      data.push(startingValue < 0 ? startingValue : 0);
      values.forEach((item) => {
         let newValue = startingValue + item.value;
         if (startingValue < 0 && newValue > 0) {
            data.push(startingValue);
            startingValue = newValue;
            return;
         }
         if (newValue < 0) {
            data.push(newValue);
            startingValue = newValue;
         } else {
            data.push(0);
            startingValue = newValue;
         }
      });
      data.push(0);
      return data;
   }

   let data = {
      labels: labels,

      datasets: [
         {
            label: "",
            data: createHiddenDataset(),
            datalabels: {
               display: false,
            },
            backgroundColor: "#ffffff00",
            barPercentage: barPercentage,
         },
         {
            label: "",
            barPercentage: barPercentage,
            datalabels: {
               display: false,
            },
            data: createNegativePositiveDataset(),
            backgroundColor: (ctx: any) => {
               if (ctx.index === 0 || ctx.index === labels.length - 1) return theme.palette.secondary.light;
               if (ctx.index === labels.length - 1) return green[600];
               let item = values[ctx.index - 1];
               if (item && item.value < 0) return red[600];
               return green[600];
            },
         },
         {
            label: "",
            data: createBarDataset(),
            barPercentage: barPercentage,
            datalabels: {
               display: true,
               anchor: "end",
               align: "end",
               offset: 6,
               color: "white",
               backgroundColor: "black",
               formatter: (value: any, context: any) => {
                  return Math.round(value).toLocaleString("en-US");
               },
            } as any,
            backgroundColor: (ctx: any) => {
               if (ctx.index === 0 || ctx.index === labels.length - 1) return theme.palette.secondary.light;
               if (ctx.index === labels.length - 1) return green[600];
               let item = values[ctx.index - 1];
               if (item && item.value < 0) return red[600];
               return green[600];
            },
         },
      ],
   };

   return (
      <Chart
         plugins={[ChartDataLabels]}
         type="bar"
         data={data}
         options={barOptions}
         height={options.direction === "horizontal" ? 300 + values.length * 60 : 300}
      />
   );
}
