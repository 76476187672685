import { Delete } from "@mui/icons-material";
import {
   Autocomplete,
   Button,
   Card,
   CardContent,
   CardHeader,
   Collapse,
   Grid,
   IconButton,
   Table,
   TableBody,
   TableCell,
   TableHead,
   TableRow,
   TextField,
   useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { GetClients } from "../../api/GetClients";
import { Dividend, GetDividends } from "../../api/GetDividends";
import { AddDividend } from "../../api/AddDividend";
import { DeleteDividend } from "../../api/DeleteDividend";
import { getDistinctObjectsByPropertyAndTransform } from "../../utils/getDistinctObjects";

type NewEvent = {
   client: string;
   amount: number;
   date: string;
   description: string;
};

export default function Dividends() {
   const theme = useTheme();
   const isMobilePhone = useMediaQuery(theme.breakpoints.down("sm"));

   const [clients, setClients] = useState<string[]>([]);
   const [selectedClient, setSelectedClient] = useState<string | null>(null);
   const [dividends, setDividends] = useState<Dividend[]>([]);

   const [date, setDate] = useState<Moment | null>(null);
   const [text, setText] = useState<string>("");
   const [amount, setAmount] = useState<string>("0");

   const onChangeDate = (newValue: Moment | null) => setDate(newValue);

   const onToggleClient = (client: string | null) => {
      setSelectedClient((state) => {
         if (client === null) return null;
         if (state === client) return null;
         return client;
      });
   };

   useEffect(() => {
      getData();
      async function getData() {
         let [clients] = await Promise.all([GetClients(["Family Office Bergen domene", "Family Office Oslo domene"])]);
         setClients(
            clients.filter(
               (item) => !item.endsWith("domene") && !item.endsWith("konsern") && !item.endsWith("konsolidering")
            )
         );
      }
   }, []);

   const onPostNewTransaction = async () => {
      if (!date || !selectedClient || selectedClient === "Velg alle") return;
      let dividend: Omit<Dividend, "id"> = {
         date: date.format("DD/MM/YYYY"),
         description: text,
         amount: parseInt(amount),
         client: selectedClient,
      };
      let result = await AddDividend(dividend);
      getTransactions();
      /* clearInput(); */
   };

   const onDeleteDividend = async (dividend: Dividend) => {
      let wasDeleted = await DeleteDividend(dividend);
      if (wasDeleted) {
         getTransactions();
         return;
      }
      alert("En feil oppsto. Vennligst prøv igjen.");
   };

   const hasError = () => {
      if (!date || text === "" || !selectedClient) return true;
      return false;
   };
   function clearInput() {
      setText("");
      setAmount("0");
      setDate(null);
   }

   useEffect(() => {
      if (!selectedClient) {
         setDividends([]);
         return;
      }
      getTransactions();
   }, [selectedClient]);

   async function getTransactions() {
      if (!selectedClient) return;
      let data = await GetDividends({
         clients: selectedClient === "Velg alle" ? [] : [selectedClient],
         periodFrom: "202301",
         periodTo: "209901",
      });
      setDividends(data);
   }

   return (
      <Grid item container xs={12} spacing={2}>
         <Grid item xs={12}>
            <Autocomplete
               value={selectedClient}
               sx={{
                  maxWidth: 400,
                  width: "90%",
                  backgroundColor: "primary.contrastText",
                  ".MuiInputBase-root": { fontSize: 12 },
               }}
               size="small"
               onChange={(event, value) => onToggleClient(value)}
               options={["Velg alle", ...clients]}
               renderInput={(params) => (
                  <TextField
                     sx={{
                        fontSize: 11,
                     }}
                     {...params}
                     InputLabelProps={{ sx: { fontSize: 11 } }}
                     label="Klient"
                  />
               )}
            />
         </Grid>
         <Grid item xs={12}>
            <Grid item container xs={12} spacing={2}>
               <Grid item container xs={12} spacing={1}>
                  <Grid item xs={12}>
                     <Collapse in={selectedClient !== null && selectedClient !== "Velg alle"}>
                        <Card>
                           <CardHeader title="Registrer utbytte"></CardHeader>
                           <CardContent>
                              <Grid item container xs={12} spacing={2}>
                                 <Grid item container xs={12} sm={6}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                       <DatePicker
                                          sx={{ width: "100%" }}
                                          label="Dato"
                                          value={date}
                                          onChange={onChangeDate}
                                          format={"DD/MM/YYYY"}
                                       ></DatePicker>
                                    </LocalizationProvider>
                                 </Grid>

                                 <Grid item xs={12} sm={6}>
                                    <TextField
                                       fullWidth
                                       label="Beløp"
                                       value={amount === "-" ? "-" : parseInt(amount).toLocaleString("en-US")}
                                       inputProps={{
                                          style: {
                                             textAlign: "right",
                                             color: parseInt(amount) < 0 ? "red" : "black",
                                          },
                                       }}
                                       onChange={(e) => {
                                          if (e.target.value === "") {
                                             setAmount("0");
                                             return;
                                          }
                                          if (e.target.value === "-") {
                                             setAmount("-");
                                             return;
                                          }
                                          setAmount(e.target.value.replaceAll(",", ""));
                                       }}
                                    />
                                 </Grid>
                                 <Grid item xs={12}>
                                    <TextField
                                       fullWidth
                                       label="Beskrivelse"
                                       value={text}
                                       onChange={(e) => setText(e.target.value)}
                                    />
                                 </Grid>
                                 <Grid item container xs={12} sx={{ mt: 2 }} justifyContent={"center"}>
                                    <Button disabled={hasError()} onClick={onPostNewTransaction} variant="contained">
                                       LAGRE
                                    </Button>
                                 </Grid>
                              </Grid>
                           </CardContent>
                        </Card>
                     </Collapse>
                  </Grid>
               </Grid>

               <Grid item xs={12}>
                  <Card>
                     <CardHeader title="Utbytte"></CardHeader>
                     <CardContent>
                        <Table size="small">
                           <TableHead>
                              <TableRow>
                                 <TableCell>Klient</TableCell>
                                 <TableCell>Dato</TableCell>
                                 <TableCell>Beskrivelse</TableCell>
                                 <TableCell align="right">Beløp</TableCell>
                                 <TableCell sx={{ width: 100 }}></TableCell>
                              </TableRow>
                           </TableHead>
                           <TableBody>
                              {getDistinctObjectsByPropertyAndTransform(dividends, "client", (c) => c.client).map(
                                 (client, index) =>
                                    dividends
                                       .filter((d) => d.client === client)
                                       .map((item, dIndex) => (
                                          <>
                                             <TableRow
                                                sx={{
                                                   "& td": {
                                                      pt: index > 0 && dIndex === 0 ? 5 : undefined,
                                                   },
                                                }}
                                             >
                                                <TableCell>{item.client}</TableCell>
                                                <TableCell>{moment(item.date).format("DD/MM/YY")}</TableCell>
                                                <TableCell>{item.description}</TableCell>

                                                <TableCell align="right">
                                                   {item.amount.toLocaleString("en-US")}
                                                </TableCell>
                                                <TableCell>
                                                   <Grid item container xs={12} spacing={1} justifyContent={"right"}>
                                                      <Grid item>
                                                         <IconButton
                                                            onClick={() => onDeleteDividend(item)}
                                                            size="small"
                                                         >
                                                            <Delete fontSize="small" />
                                                         </IconButton>
                                                      </Grid>
                                                   </Grid>
                                                </TableCell>
                                             </TableRow>
                                             {dIndex === dividends.filter((d) => d.client === client).length - 1 && (
                                                <TableRow>
                                                   <TableCell
                                                      sx={{ borderBottom: "0px solid black" }}
                                                      colSpan={3}
                                                   ></TableCell>
                                                   <TableCell
                                                      align="right"
                                                      sx={{ fontWeight: "bold", borderBottom: "0px solid black" }}
                                                   >
                                                      {dividends
                                                         .filter((d) => d.client === client)
                                                         .reduce((value, item) => (value += item.amount), 0)
                                                         .toLocaleString("en-US")}
                                                   </TableCell>
                                                   <TableCell sx={{ borderBottom: "0px solid black" }} />
                                                </TableRow>
                                             )}
                                          </>
                                       ))
                              )}
                           </TableBody>
                        </Table>
                     </CardContent>
                  </Card>
               </Grid>
            </Grid>
         </Grid>
      </Grid>
   );
}
