import { DefaultAxiosGetRequest } from "./ApiBase";

export interface TrialBalanceAccountDto {
   accountId: string;
   accountName: string;
   amount: number;
   year: number;
   month: number;
   company: string;
   accountType: string;
   counterparty: string;
   financialStatementCaption: string | null;
   financialStatementRank: number | null;
}

export interface TransactionQuery {
   periodFrom: string;
   periodTo: string;
   accounts?: string[];
   clients?: string[];
   clientsDbId?: number[];
   includeProfitLoss?: boolean;
   includeBalanceSheet?: boolean;
   aggregateTimePeriods?: boolean;
}

export async function GetTrialBalance(parameters: TransactionQuery): Promise<TrialBalanceAccountDto[]> {
   try {
      let result = await DefaultAxiosGetRequest<TrialBalanceAccountDto[], TransactionQuery>(
         `GetTrialBalance`,
         parameters
      );
      return result.data;
   } catch (e) {
      throw e;
   }
}
