import { Card, CardHeader, Grid, useTheme } from "@mui/material";

type TextboxProps = {
  title: string;
  hightlightedText: string;
};

export default function Textbox({ title, hightlightedText }: TextboxProps) {
  const theme = useTheme();

  return (
    <Grid item xs={12}>
      <Card>
        <CardHeader
          sx={{ backgroundColor: theme.palette.primary.light }}
          title={
            <Grid
              item
              container
              xs={12}
              sx={{
                color: theme.palette.primary.contrastText,
                fontSize: 14,
              }}
              justifyContent="center"
            >
              <Grid item>{title}</Grid>
            </Grid>
          }
        ></CardHeader>
        <Grid
          item
          container
          xs={12}
          alignContent="center"
          sx={{ mt: 1, mb: 1 }}
        >
          <Grid
            item
            container
            justifyContent="center"
            xs={12}
            sx={{
              /* color: theme.palette.primary.light, */
              fontSize: 24,
              fontWeight: "bold",
            }}
          >
            <Grid item>{hightlightedText}</Grid>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}
