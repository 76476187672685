import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AccountingTransactionDto, GetAccountingTransactions } from "../../api/GetAccountingTransactions";
import { TrialBalanceAccountDto } from "../../api/GetTrialBalance";
import { DataContext } from "../../context/DataContext";
import moment from "moment";

export default function Cash() {
   const { trialBalance, clients } = useContext(DataContext);
   const [loading, setLoading] = useState<boolean>(false);
   const [cashAccounts, setCashAccounts] = useState<TrialBalanceAccountDto[]>([]);

   const [transactions, setTransactions] = useState<AccountingTransactionDto[]>([]);

   useEffect(() => {
      try {
         if (!trialBalance) return;
         let relevantAccounts = trialBalance.filter(
            (item) =>
               item.financialStatementCaption === "Bankinnskudd, kontanter og lignende" &&
               item.accountId !== "1999" &&
               item.amount !== 0
         );

         let transactionFilter = relevantAccounts.map((item) => item.accountId);
         getTransactions(transactionFilter);

         setCashAccounts(relevantAccounts);
      } catch (e) {
         console.log("Unable to fetch required resource.");
      } finally {
         setLoading(false);
      }
   }, [trialBalance]);

   async function getTransactions(accounts: string[]) {
      if (!clients) return;
      let data = await GetAccountingTransactions({
         periodFrom: "202401",
         periodTo: "202402",
         clients: clients,
         accounts: accounts,
      });
      setTransactions(data);
   }

   function getLastTransactions() {
      let sortedTransactions = transactions.sort((a, b) =>
         moment(b.transactionDate).isBefore(moment(a.transactionDate)) ? -1 : 1
      );

      return sortedTransactions.slice(0, 10);
   }

   return (
      <Grid item container xs={12} sx={{ height: 212 }}>
         <Grid item container xs={12} alignContent="flex-start">
            <Grid item container xs={12} sx={{ color: "text.secondary", fontSize: 14 }} justifyContent="center">
               <Grid item>SALDO</Grid>
            </Grid>
            <Grid item container justifyContent="center" xs={12} sx={{ color: "text.primary", fontSize: 34 }}>
               <Grid item>
                  {(cashAccounts.reduce((value, item) => (value += item.amount), 0) / 1000 / 1000).toFixed(1)}M
               </Grid>
            </Grid>
         </Grid>

         <Grid item container xs={12} alignContent="flex-end">
            <TableContainer sx={{ height: 120 }}>
               <Table size="small" sx={{ "& .MuiTableCell-root": { fontSize: 10 } }}>
                  <TableHead>
                     <TableRow
                        sx={{
                           "& th": { backgroundColor: "lightgrey", fontWeight: "bold" },
                        }}
                     >
                        <TableCell>Siste transaksjoner</TableCell>
                        <TableCell>Dato</TableCell>
                        <TableCell align="right">Beløp</TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {getLastTransactions().map((transaction) => (
                        <TableRow>
                           <TableCell>{`${transaction.description == null ? "" : transaction.description}`}</TableCell>
                           <TableCell>{moment(transaction.transactionDate).format("DD/MM/YY")}</TableCell>
                           <TableCell align="right">{Math.round(transaction.amount).toLocaleString("en-US")}</TableCell>
                        </TableRow>
                     ))}
                     {/* <TableRow>
                          <TableCell sx={{ fontWeight: "bold" }}>Totalt</TableCell>
                          <TableCell align="right" sx={{ fontWeight: "bold" }}>
                             {Math.round(
                                cashAccounts.reduce((value, item) => (value += item.amount), 0)
                             ).toLocaleString("en-US")}
                          </TableCell>
                       </TableRow> */}
                  </TableBody>
               </Table>
            </TableContainer>
         </Grid>
      </Grid>
   );
}
