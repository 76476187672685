import { DefaultAxiosGetRequest } from "./ApiBase";
import { AccountingTransactionDto } from "./GetAccountingTransactions";
import { TransactionQuery } from "./GetTrialBalance";

export interface GetLiabilitiesQuery {
    period: string;
}

export interface LiabilityDto {
    month: number;
    year: number;
    category: string;
    project: string;
    amount: number;
}

export async function GetLiabilities(parameters: GetLiabilitiesQuery) : Promise<LiabilityDto[]> {
    try{
        let result = await DefaultAxiosGetRequest<LiabilityDto[], GetLiabilitiesQuery>(`GetLiabilities`, parameters);
        return result.data;
    }
    catch(e){
        throw e;
    }
}

