import { DefaultAxiosGetRequest } from "./ApiBase";

export interface PaintingDto {
    id: number;
    name: string | null;
    artist: string;
    technique: string;
    size: string;
    value: number;
    acquiredYear: number;
}

export async function GetPaintings() : Promise<PaintingDto[]> {
    try{
        let result = await DefaultAxiosGetRequest<PaintingDto[], undefined>(`GetPaintings`, undefined);
        return result.data;
    }
    catch(e){
        throw e;
    }
}