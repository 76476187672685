import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { Warning } from "@mui/icons-material";

export default function Dashboard() {
   const { loading, loggedInUser } = useContext(AuthContext);

   return (
      <>
         {loading ? (
            <Grid
               sx={{ height: "75vh" }}
               item
               container
               xs={12}
               justifyContent={"center"}
               alignItems={"center"}
               spacing={2}
            >
               <Grid item container xs={12} justifyContent={"center"}>
                  <CircularProgress size={80} />
               </Grid>
               <Grid item container xs={12} justifyContent={"center"}>
                  <Typography variant="body2">Henter brukerprofil..</Typography>
               </Grid>
            </Grid>
         ) : (
            <Grid
               item
               container
               xs={12}
               spacing={2}
               sx={{ height: "75vh" }}
               justifyContent={"center"}
               alignContent={"center"}
            >
               {loggedInUser && loggedInUser.memberships.length === 0 && (
                  <>
                     <Grid item container xs={12} justifyContent={"center"}>
                        <Warning sx={{ fontSize: 48 }} />
                     </Grid>
                     <Grid item container xs={12} justifyContent={"center"}>
                        <h4>
                           Du har for øyeblikket ingen brukerrettigheter knyttet til siden. Kontakt administrator for å
                           etterspørre rettigheter.
                        </h4>
                     </Grid>
                  </>
               )}
            </Grid>
         )}
      </>
   );
}
