import {
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../context/DataContext";
import * as XLSX from "xlsx";
import { Header, TimePeriod } from "../../api/GetFinancialStatements";
import DialogWindow from "../../components/DialogWindow";
import {
  AccountingTransactionDto,
  GetAccountingTransactions,
} from "../../api/GetAccountingTransactions";
import { Refresh } from "@mui/icons-material";
import {
  getDistinctObjectsByProperty,
  getDistinctObjectsByPropertyAndTransform,
} from "../../utils/getDistinctObjects";

type Vendor = {
  name: string;
  amount: number;
};

export default function IncomeStatement() {
  const { financialStatement, clients } = useContext(DataContext);

  const [selectedItem, setSelectedItem] = useState<TimePeriod | null>(null);
  const [transactions, setTransactions] = useState<AccountingTransactionDto[]>(
    []
  );

  const [mode, setMode] = useState<"vendor" | "transaction">("vendor");

  const [errorLoading, setErrorLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getData();
  }, [selectedItem]);

  async function getData() {
    try {
      if (!selectedItem || !clients) return;
      setErrorLoading(false);
      setLoading(true);
      let data = await GetAccountingTransactions({
        periodFrom: selectedItem.startingPeriod,
        periodTo: selectedItem.closingPeriod,
        clients: clients,
        accounts: selectedItem.accounts.map((item) => item.accountId),
      });
      setLoading(false);
      setErrorLoading(false);
      setTransactions(data);
    } catch (e) {
      setLoading(false);
      setErrorLoading(true);
    }
  }

  function onCloseDialog() {
    setSelectedItem(null);
    setTransactions([]);
  }

  function getIncomeStatement(): Header[] | null | undefined {
    try {
      if (!financialStatement || financialStatement.length === 0) return null;
      let relevantTypes = financialStatement[0].accountTypes.filter(
        (item) =>
          item.id > 5 &&
          item.id < 10 &&
          item.captions.reduce(
            (value, item) =>
              value === false
                ? false
                : item.allPeriodsAreZero === true
                ? true
                : false,
            false
          ) === false
      );
      return relevantTypes;
    } catch (e) {
      console.log(e);
    }
  }

  function drawItemsByVendor() {
    return getDistinctObjectsByPropertyAndTransform(
      transactions,
      "vendor",
      (t) => ({
        name: t.vendor,
        amount: transactions
          .filter((item) => item.vendor === t.vendor)
          .reduce((v, i) => (v += i.amount), 0),
      })
    )
      .sort((a, b) => Math.abs(b.amount) - Math.abs(a.amount))
      .map((t) => (
        <TableRow>
          <TableCell>
            {t.name === "" || t.name === undefined ? "Udefinert" : t.name}
          </TableCell>
          <TableCell align="right">
            {Math.round(-t.amount).toLocaleString("en-US")}
          </TableCell>
        </TableRow>
      ));
  }

  function drawItemsByTransaction() {
    return transactions
      .sort((a, b) => Math.abs(b.amount) - Math.abs(a.amount))
      .map((t) => (
        <TableRow>
          <TableCell>{t.description}</TableCell>
          <TableCell align="right">
            {Math.round(-t.amount).toLocaleString("en-US")}
          </TableCell>
        </TableRow>
      ));
  }

  return (
    <Grid item container xs={12} sx={{ minHeight: 300 }}>
      <DialogWindow
        title={"Detaljer"}
        open={selectedItem !== null}
        onClose={onCloseDialog}
      >
        <Grid item container xs={12} justifyContent={"center"}>
          {loading ? (
            <Grid item sx={{ pt: 5 }}>
              <CircularProgress size={50} />
            </Grid>
          ) : errorLoading ? (
            <Grid item xs={12} sx={{ pt: 6 }}>
              <Grid item container xs={12} justifyContent="center">
                <Typography variant="body2">
                  Feil ved lasting av data. Vennlist prøv igjen.
                </Typography>
              </Grid>
              <Grid
                item
                container
                xs={12}
                justifyContent={"center"}
                sx={{ pt: 6 }}
              >
                <Grid item>
                  <IconButton onClick={() => getData()}>
                    <Refresh sx={{ fontSize: 48 }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Grid item xs={12} sx={{ p: 3 }}>
                <ButtonGroup size="small">
                  <Button
                    onClick={() => setMode("vendor")}
                    variant={mode === "vendor" ? "contained" : "outlined"}
                  >
                    Leverandør
                  </Button>
                  <Button
                    onClick={() => setMode("transaction")}
                    variant={mode === "transaction" ? "contained" : "outlined"}
                  >
                    Transaksjon
                  </Button>
                </ButtonGroup>
              </Grid>
              <TableContainer
                sx={{
                  p: 3,
                }}
              >
                <Table
                  size="small"
                  sx={{
                    ".MuiTableCell-head": {
                      fontWeight: "bold",
                    },
                    "& th": {
                      fontSize: 11,
                      padding: 1,
                    },
                    "& td": {
                      fontSize: 11,
                      padding: 1,
                      borderBottom: "none",
                    },
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        {mode === "vendor"
                          ? "Leverandør"
                          : "Transaksjonsbeskrivelse"}
                      </TableCell>
                      <TableCell align="right">Beløp</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {mode === "vendor"
                      ? drawItemsByVendor()
                      : drawItemsByTransaction()}
                    <TableRow
                      sx={{
                        "& td": {
                          backgroundColor: "lightgrey",
                          fontWeight: "bold",
                        },
                      }}
                    >
                      <TableCell>Totalt</TableCell>
                      <TableCell align="right">
                        {Math.round(
                          -transactions.reduce(
                            (value, item) => (value += item.amount),
                            0
                          ) ?? 0
                        ).toLocaleString("en-US")}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Grid>
      </DialogWindow>
      <TableContainer>
        <Table
          size="small"
          sx={{
            ".MuiTableCell-head": {
              fontWeight: "bold",
            },
            "& th": {
              fontSize: 11,
              padding: 1,
            },
            "& td": {
              fontSize: 11,
              padding: 1,
              borderBottom: "none",
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell width="40%">
                <Typography
                  sx={{ fontSize: 14 }}
                  variant="body2"
                  fontWeight={"bold"}
                >
                  {financialStatement &&
                    financialStatement[0].client.toUpperCase()}
                </Typography>
              </TableCell>
              {financialStatement &&
                financialStatement[0].timePeriods.map((item) => (
                  <TableCell sx={{ fontSize: 16 }} width="20%" align="right">
                    <Typography
                      sx={{ fontSize: 14 }}
                      variant="body2"
                      color={"GrayText"}
                    >
                      {item.periodTo}
                    </Typography>
                  </TableCell>
                ))}
              <TableCell
                width="20%"
                align="right"
                /* sx={{ backgroundColor: "lightgrey" }} */
              >
                <Typography
                  sx={{ fontSize: 14 }}
                  variant="body2"
                  color={"GrayText"}
                >
                  YTD
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getIncomeStatement()?.map((item) => (
              <>
                <TableRow sx={{ "& td": { fontWeight: "bold" } }}>
                  <TableCell
                    colSpan={
                      (financialStatement
                        ? financialStatement[0].timePeriods.length
                        : 0) + 1
                    }
                  >
                    {item.name}
                  </TableCell>
                  <TableCell
                  /* sx={{ backgroundColor: "lightgrey", fontWeight: "bold" }} */
                  ></TableCell>
                </TableRow>
                {item.captions
                  .filter((caption) => caption.allPeriodsAreZero === false)
                  .map((caption) => (
                    <TableRow>
                      <TableCell>{caption.name}</TableCell>
                      {caption.timePeriods.map((period) => (
                        <TableCell
                          align="right"
                          sx={{
                            "&:hover": {
                              cursor: "pointer",
                              backgroundColor: "primary.main",
                              color: "primary.contrastText",
                            },
                          }}
                          onClick={() => setSelectedItem(period)}
                        >
                          {Math.round(period.amount).toLocaleString("en-US")}
                        </TableCell>
                      ))}
                      <TableCell
                        align="right"
                        sx={{
                          /* backgroundColor: "lightgrey", */
                          /* fontWeight: "bold", */
                          "&:hover": {
                            cursor: "pointer",
                            backgroundColor: "primary.main",
                            color: "primary.contrastText",
                          },
                        }}
                        onClick={() =>
                          setSelectedItem({
                            startingPeriod:
                              caption.timePeriods[0].startingPeriod,
                            closingPeriod:
                              caption.timePeriods[
                                caption.timePeriods.length - 1
                              ].closingPeriod,
                            amount: 0,
                            accounts: caption.timePeriods.flatMap(
                              (item) => item.accounts
                            ),
                          })
                        }
                      >
                        {Math.round(
                          caption.timePeriods.reduce(
                            (value, item) => (value += item.amount),
                            0
                          )
                        ).toLocaleString("en-US")}
                      </TableCell>
                    </TableRow>
                  ))}
                {item.captions.filter((item) => !item.allPeriodsAreZero)
                  .length > 0 && (
                  <TableRow>
                    <TableCell
                      sx={{ backgroundColor: "lightgrey", fontWeight: "bold" }}
                    >{`Sum ${item.name.toLowerCase()}er`}</TableCell>
                    {financialStatement &&
                      financialStatement[0].timePeriods.map((period) => (
                        <TableCell
                          align="right"
                          sx={{
                            backgroundColor: "lightgrey",
                            fontWeight: "bold",
                          }}
                        >
                          {item.captions
                            .flatMap((caption) => caption.timePeriods)
                            .filter(
                              (period2) =>
                                period2.closingPeriod === period.periodTo
                            )
                            .reduce((value, item) => (value += item.amount), 0)
                            .toLocaleString("en-US")}
                        </TableCell>
                      ))}
                    {financialStatement && (
                      <TableCell
                        align="right"
                        sx={{
                          backgroundColor: "lightgrey",
                          fontWeight: "bold",
                        }}
                      >
                        {item.captions
                          .flatMap((caption) => caption.timePeriods)
                          .reduce((value, p) => (value += p.amount), 0)
                          .toLocaleString("en-US")}
                      </TableCell>
                    )}
                  </TableRow>
                )}
              </>
            ))}
            <TableRow
              sx={{
                "& td": { fontWeight: "bold", backgroundColor: "lightgray" },
              }}
            >
              <TableCell>Resultat før skatt</TableCell>
              {financialStatement &&
                financialStatement[0].timePeriods.map((period, index) => (
                  <TableCell align="right">
                    {financialStatement &&
                      Math.round(
                        (getIncomeStatement() ?? []).reduce(
                          (value, item) =>
                            (value += item.captions.reduce(
                              (value2, item2) =>
                                (value2 += item2.timePeriods[index].amount),
                              0
                            )),
                          0
                        )
                      ).toLocaleString("en-US")}
                  </TableCell>
                ))}
              <TableCell
                align="right"
                sx={{ backgroundColor: "lightgrey", fontWeight: "bold" }}
              >
                {Math.round(
                  (getIncomeStatement() ?? []).reduce(
                    (value, item) =>
                      (value += item.captions
                        .flatMap((item) => item.timePeriods)
                        .reduce(
                          (value2, item2) => (value2 += item2.amount),
                          0
                        )),
                    0
                  )
                ).toLocaleString("en-US")}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
