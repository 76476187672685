import { DefaultAxiosGetRequest } from "./ApiBase";

export interface Disbursement {
    year: number;
    category: string;
    categoryId: number;
    budget: number;
    cpiAdjustedBudget: number;
    projects: ProjectDto[];
}

export interface ProjectDto {
    name: string;
    categoryId: string;
    categoryName: string;
    amount: number;
    cpiAdjustedAmount: number;
    year: number;
}

export async function GetDisbursementHistory() : Promise<Disbursement[]> {
    try{
        let result = await DefaultAxiosGetRequest(`GetDisbursementHistory`, undefined);
        return result.data as Disbursement[];
    }
    catch(e){
        throw e;
    }
}