import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { GetClients } from "../../api/GetClients";
import { UpdateUserAccess } from "../../api/UpdateUserAccess";
import { GetUserAccess } from "../../api/GetUserAccess";
import { AuthContext } from "../../context/AuthContext";
import { useTheme } from "@mui/system";
import { GetUsers, User } from "../../api/GetUsers";

export default function UserAccess() {
  const theme = useTheme();
  const isMobilePhone = useMediaQuery(theme.breakpoints.down("sm"));

  const [clients, setClients] = useState<string[]>([]);
  const [selectedClients, setSelectedClients] = useState<string[]>([]);
  const [currentAccess, setCurrentAccess] = useState<string[]>([]);
  const [updatingUserAccess, setUpdatingUserAccesss] = useState<boolean>(false);
  const { loggedInUser, setLoggedInUser } = useContext(AuthContext);
  const [users, setUsers] = useState<User[]>([]);

  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [showAllClients, setShowAllClients] = useState<boolean>(false);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    let [clients, users] = await Promise.all([
      GetClients(["Family Office Bergen domene", "Family Office Oslo domene"]),
      GetUsers(),
    ]);
    setClients(
      clients.filter(
        (item) =>
          !item.endsWith("domene") &&
          !item.endsWith("konsern") &&
          !item.endsWith("konsolidering")
      )
    );
    setUsers(users);
  }

  async function getClients() {
    let data: string[] = [];
    if (showAllClients) {
      data = await GetClients();
    } else {
      data = await GetClients([
        "Family Office Bergen domene",
        "Family Office Oslo domene",
      ]);
    }
    setClients(
      data.filter(
        (item) => !item.endsWith("domene") && !item.endsWith("konsern")
      )
    );
  }

  useEffect(() => {
    getClients();
  }, [showAllClients]);

  useEffect(() => {
    onGetUserAccess();
  }, [selectedUser]);

  const onChangeUser = (event: SelectChangeEvent<string | null>) => {
    setSelectedUser(event.target.value);
  };

  const onToggleClient = (client: string) => {
    setSelectedClients((state) => {
      if (state.includes(client))
        return state.filter((item) => item !== client);
      return [...state, client];
    });
  };

  async function onGetUserAccess() {
    if (!selectedUser) return;
    let data = await GetUserAccess({ id: selectedUser });
    setSelectedClients(data);
    setCurrentAccess(data);
  }

  async function onUpdateUserAccess() {
    try {
      if (!selectedUser) return;
      setUpdatingUserAccesss(true);

      let data = {
        id: selectedUser,
        clients: selectedClients,
      };

      let response = await UpdateUserAccess(data);
      setUpdatingUserAccesss(false);
    } catch (e) {
      alert("Feil ved opplasting av data. Vennligst prøv igjen.");
      setUpdatingUserAccesss(false);
    }
  }

  function onSelectAll() {
    setSelectedClients([...clients]);
  }

  function onRemovevAll() {
    setSelectedClients([]);
  }

  return (
    <Card>
      <CardContent>
        <Grid item container xs={12} spacing={3}>
          <Grid item container xs={12}>
            <Typography variant="h6">Alloker brukerrettigheter</Typography>
          </Grid>

          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} sm={6}>
              <Select
                size="small"
                sx={{ maxWidth: 400, width: "90%" }}
                value={selectedUser}
                onChange={onChangeUser}
                label="Bruker"
              >
                {users.map((user) => (
                  <MenuItem value={user.userId}>{user.name}</MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={6}
              spacing={1}
              justifyContent={isMobilePhone ? "left" : "right"}
            >
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      value={showAllClients}
                      onChange={(e) => setShowAllClients(e.target.checked)}
                    />
                  }
                  label="Vis alle"
                />
              </Grid>
              <Grid item>
                <Button
                  size="small"
                  sx={{ width: 125 }}
                  variant="contained"
                  onClick={() => onSelectAll()}
                >
                  VELG ALLE
                </Button>
              </Grid>
              <Grid item>
                <Button
                  size="small"
                  sx={{ width: 125 }}
                  variant="contained"
                  onClick={() => onRemovevAll()}
                >
                  FJERN ALLE
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table size="small" sx={{ "& td": { fontSize: 10 } }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Klient</TableCell>
                    <TableCell align="right">Tilgang</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clients.map((client) => (
                    <TableRow>
                      <TableCell>{client}</TableCell>
                      <TableCell align="right">
                        <Switch
                          size="small"
                          checked={selectedClients.includes(client)}
                          onClick={() => onToggleClient(client)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item container xs={12} justifyContent={"center"}>
            {updatingUserAccess ? (
              <CircularProgress size={30} />
            ) : (
              <Button
                color="primary"
                variant="contained"
                disabled={selectedUser === null}
                onClick={() => onUpdateUserAccess()}
              >
                LAGRE
              </Button>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
