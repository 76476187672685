import { DefaultAxiosGetRequest, DefaultAxiosPostRequest } from "./ApiBase";
import { TransactionQuery, TrialBalanceAccountDto } from "./GetTrialBalance";

export interface GetFinancialStatementsQuery {
   timePeriods: QueryTimePeriods[];
   clients: string[];
}

export interface FinancialStatement {
   client: string;
   accountTypes: Header[];
   timePeriods: QueryTimePeriods[];
}

export interface QueryTimePeriods {
   periodFrom: string;
   periodTo: string;
}

export interface Header {
   id: number;
   name: string;
   captions: Caption[];
}

export interface Caption {
   name: string;
   timePeriods: TimePeriod[];
   rank: number;
   allPeriodsAreZero: boolean;
}

export interface TimePeriod {
   startingPeriod: string;
   closingPeriod: string;
   amount: number;
   accounts: TrialBalanceAccountDto[];
}

export async function GetFinancialStatements(parameters: GetFinancialStatementsQuery): Promise<FinancialStatement[]> {
   try {
      let result = await DefaultAxiosPostRequest<FinancialStatement[], GetFinancialStatementsQuery>(
         `GetFinancialStatements`,
         parameters
      );
      return result.data;
   } catch (e) {
      throw e;
   }
}
