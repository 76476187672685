import { DefaultAxiosGetRequest } from "./ApiBase";

export async function GetClients(groups?: string[]) : Promise<string[]> {
    try{
        let result = await DefaultAxiosGetRequest(`GetClients`, groups ? { groups: groups} : undefined);
        return result.data as string[];
    }
    catch(e){
        throw e;
    }
}