import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { AuthContext } from "../../context/AuthContext";
import { GetUser, User } from "../../api/GetUser";
import "./LoginPage.css";
import { GriegLogo } from "../../components/Logo";

export default function LoginPage() {
  const { setLoggedInUser, loading, toggleLoading } = useContext(AuthContext);
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const initializeSignIn = async () => {
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      instance.handleRedirectPromise().then((res) => {
        instance.loginRedirect();
      });
    }
  };

  //const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      const account = instance.getAllAccounts()[0];
      if (account) checkLogin();
    }
  }, [accounts]);

  const checkLogin = async () => {
    try {
      toggleLoading && toggleLoading();
      let user = await GetUser();
      if (process.env.NODE_ENV === "development") {
        user.isAdmin = true;
      }
      setLoggedInUser && setLoggedInUser(user);
    } catch (e) {
      alert(e);
    } finally {
      toggleLoading && toggleLoading();
    }
  };

  const onSignIn = () => {
    initializeSignIn();
  };

  /* useEffect(() => {
    initializeSignIn();
  }, [inProgress, isAuthenticated]); */

  return (
    <div className="video-container">
      <video autoPlay muted loop playsInline id="myVideo">
        <source
          src="https://grieg.no/wp-content/uploads/1315834186.mp4"
          type="video/mp4"
        />
      </video>
      <div className="logo">
        <GriegLogo width={60} height={60} />
      </div>
      <div className="caption">
        <Grid item container xs={12} spacing={5}>
          <Grid item xs={12} justifyContent={"center"}>
            <Typography color="white" variant="h3">
              Welcome to Grieg Finance
            </Typography>
          </Grid>
          <Grid item xs={12} justifyContent={"center"}>
            <Button
              variant="contained"
              sx={{ fontSize: 18 }}
              color="primary"
              onClick={onSignIn}
            >
              SIGN IN
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
