import { DefaultAxiosGetRequest } from "./ApiBase";

export interface GetEventsQuery {
   periodFrom: string;
   periodTo: string;
   clients?: string[];
}

export interface Cashflow {
   id: number;
   date: string;
   client: string;
   amount: number;
   description: string | null;
   toShareholder: boolean;
   taxPrepayment: boolean;
   paidPrivately: boolean;
}

export async function GetCashflows(parameters: GetEventsQuery): Promise<Cashflow[]> {
   try {
      let result = await DefaultAxiosGetRequest(`GetCashflows`, parameters);
      return result.data as Cashflow[];
   } catch (e) {
      throw e;
   }
}
