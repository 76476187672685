import { DefaultAxiosGetRequest, DefaultAxiosPostRequest } from "./ApiBase";
import { Cashflow } from "./GetCashflows";

export async function DeleteCashflow(cashflow: Cashflow) : Promise<boolean> {
    try{
        let result = await DefaultAxiosGetRequest(`DeleteCashflow`, { id: cashflow.id});
        return true;
    }
    catch(e){
        return false;
    }
}