import {
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import { teal } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import {
  Disbursement,
  GetDisbursementHistory,
} from "../../api/GetDisbursementHistory";
import {
  GetLiabilities,
  LiabilityDto,
} from "../../api/GetGriegFoundationLiabilities";
import {
  GetTrialBalance,
  TrialBalanceAccountDto,
} from "../../api/GetTrialBalance";
import Textbox from "../../components/Textbox";

/* ChartJS.register(ChartDataLabels); */

type LiquidityPlacements = {
  deposits: number;
  moneyMarket: number;
  bonds: number;
  shares: number;
};

const MONEY_MARKET_ACCOUNTS = ["1840", "1841"];
const BONDFUND_ACCOUNTS = ["1830", "1831"];
const SHARES_ACCOUNTS = ["1820", "1821", "1825", "1826"];
const CASH_ACCOUNTS = ["1920", "1921", "1922"];

const GRIEG_FOUNDATION_ACCOUNTING_ID = 34229470;

export default function Assets() {
  const [liquidityPlacements, setLiquidityPlacements] =
    useState<LiquidityPlacements | null>(null);

  const [disbursements, setDisbursements] = useState<Disbursement[] | null>(
    null
  );

  const [liabilities, setLiabilities] = useState<LiabilityDto[]>([]);

  const [pieChartData, setPieChartData] = useState({
    labels: ["Aksjer", "Obligasjoner", "Pengemarkedsfond", "Bank"],
    datasets: [
      {
        label: "",
        data: [0, 0, 0, 0],
        backgroundColor: [teal[100], teal[200], teal[600], teal[800]],
        borderColor: [teal[800], teal[800], teal[800], teal[800]],
        borderWidth: 1,
      },
    ],
  });

  const [trialBalance, setTrialBalance] = useState<TrialBalanceAccountDto[]>(
    []
  );

  useEffect(() => {
    getData();
    async function getData() {
      try {
        const [data, liabilities, disbursements] = await Promise.all([
          GetTrialBalance({
            clientsDbId: [GRIEG_FOUNDATION_ACCOUNTING_ID],
            periodFrom: "202301",
            periodTo: "202312",
          }),
          GetLiabilities({ period: "202312" }),
          GetDisbursementHistory(),
        ]);

        setTrialBalance(data);
        setLiabilities(liabilities);
        setDisbursements(disbursements);
      } catch (e) {
        alert(e);
      }
    }
  }, []);

  function getRiskFreeAssetTotal() {
    if (!liquidityPlacements) return 0;
    return liquidityPlacements.deposits + liquidityPlacements.moneyMarket;
  }

  function getAssetTotal() {
    if (!liquidityPlacements) return 0;
    return (
      liquidityPlacements.bonds +
      liquidityPlacements.deposits +
      liquidityPlacements.moneyMarket +
      liquidityPlacements.shares
    );
  }

  function getLiabilityTotal() {
    if (!disbursements) return 0;
    return disbursements
      ?.filter((item) => item.year === 2022)
      .reduce(
        (value, item) =>
          (value += item.projects.reduce(
            (value2, item2) => (value2 += item2.amount),
            0
          )),
        0
      );
  }

  useEffect(() => {
    if (trialBalance && trialBalance.length > 0) {
      const bank = trialBalance
        .filter((item) => CASH_ACCOUNTS.includes(item.accountId))
        .reduce((value, item) => (value += item.amount), 0);
      const moneyMarket = trialBalance
        .filter((item) => MONEY_MARKET_ACCOUNTS.includes(item.accountId))
        .reduce((value, item) => (value += item.amount), 0);
      const bonds = trialBalance
        .filter((item) => BONDFUND_ACCOUNTS.includes(item.accountId))
        .reduce((value, item) => (value += item.amount), 0);
      const shares = trialBalance
        .filter((item) => SHARES_ACCOUNTS.includes(item.accountId))
        .reduce((value, item) => (value += item.amount), 0);

      setLiquidityPlacements({
        deposits: bank,
        moneyMarket: moneyMarket,
        bonds: bonds,
        shares: shares,
      });

      setPieChartData({
        labels: ["Aksjer", "Obligasjoner", "Pengemarkedsfond", "Bank"],
        datasets: [
          {
            label: "",
            data: [shares, bonds, moneyMarket, bank],
            backgroundColor: [teal[100], teal[200], teal[600], teal[800]],
            borderColor: [teal[800], teal[800], teal[800], teal[800]],
            borderWidth: 1,
          },
        ],
      });
    }
  }, [trialBalance]);

  const years = [2017, 2018, 2019, 2020, 2021, 2022, 2023];

  return (
    <>
      <Grid item container xs={12} alignContent="center" spacing={2}>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Textbox
              title="Totale midler"
              hightlightedText={
                (liquidityPlacements &&
                  Math.round(getAssetTotal() / 1000).toLocaleString("en-US")) ??
                "0"
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Textbox
              title="Totale forpliktelser"
              hightlightedText={
                disbursements
                  ? Math.round(getLiabilityTotal() / 1000).toLocaleString(
                      "en-US"
                    )
                  : "0"
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Textbox
              title="Forpliktelser / totale midler"
              hightlightedText={
                disbursements
                  ? ((getLiabilityTotal() / getAssetTotal()) * 100).toFixed(1) +
                    "%"
                  : "0"
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Textbox
              title="Forpliktelser / risikofrie midler"
              hightlightedText={
                disbursements
                  ? (
                      (getLiabilityTotal() / getRiskFreeAssetTotal()) *
                      100
                    ).toFixed(1) + "%"
                  : "0"
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid item container xs={12}>
                  <Grid item xs={12} md={8}>
                    <Pie
                      data={pieChartData}
                      height={500}
                      options={{
                        maintainAspectRatio: false,
                        plugins: {
                          legend: {
                            display: true,
                            position: "bottom",
                          },
                          datalabels: {
                            formatter: (value: any, context: any) =>
                              Math.round(value / 1000).toLocaleString("en-US"),
                            color: "white",
                            backgroundColor: "black",
                            display: true,
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    md={4}
                    alignContent={"flex-start"}
                  >
                    {liquidityPlacements && (
                      <Table
                        size="small"
                        sx={{
                          ".MuiTableCell-head": {
                            color: "primary.contrastText",
                            backgroundColor: "primary.light",
                          },
                          "& th": {
                            fontSize: 11,
                          },
                          "& td": {
                            fontSize: 11,
                          },
                        }}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Kategori</TableCell>
                            <TableCell align="right">Beløp</TableCell>
                            <TableCell align="right">(%)</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>Aksjer</TableCell>
                            <TableCell align="right">
                              {Math.round(
                                liquidityPlacements.shares / 1000
                              ).toLocaleString("en-US")}
                            </TableCell>
                            <TableCell align="right">
                              {(
                                (liquidityPlacements.shares / getAssetTotal()) *
                                100
                              ).toFixed(1) + "%"}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Obligasjoner</TableCell>
                            <TableCell align="right">
                              {Math.round(
                                liquidityPlacements.bonds / 1000
                              ).toLocaleString("en-US")}
                            </TableCell>
                            <TableCell align="right">
                              {(
                                (liquidityPlacements.bonds / getAssetTotal()) *
                                100
                              ).toFixed(1) + "%"}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "& td": {
                                fontWeight: "bold",
                                backgroundColor: "lightgrey",
                              },
                            }}
                          >
                            <TableCell>Risikoplasseringer</TableCell>
                            <TableCell align="right">
                              {Math.round(
                                (liquidityPlacements.bonds +
                                  liquidityPlacements.shares) /
                                  1000
                              ).toLocaleString("en-US")}
                            </TableCell>
                            <TableCell align="right">
                              {(
                                ((liquidityPlacements.bonds +
                                  liquidityPlacements.shares) /
                                  getAssetTotal()) *
                                100
                              ).toFixed(1) + "%"}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell colSpan={3} />
                          </TableRow>

                          <TableRow>
                            <TableCell>Pengemarkedsfond</TableCell>
                            <TableCell align="right">
                              {Math.round(
                                liquidityPlacements.moneyMarket / 1000
                              ).toLocaleString("en-US")}
                            </TableCell>
                            <TableCell align="right">
                              {(
                                (liquidityPlacements.moneyMarket /
                                  getAssetTotal()) *
                                100
                              ).toFixed(1) + "%"}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Bank</TableCell>
                            <TableCell align="right">
                              {Math.round(
                                liquidityPlacements.deposits / 1000
                              ).toLocaleString("en-US")}
                            </TableCell>
                            <TableCell align="right">
                              {(
                                (liquidityPlacements.deposits /
                                  getAssetTotal()) *
                                100
                              ).toFixed(1) + "%"}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "& td": {
                                fontWeight: "bold",
                                backgroundColor: "lightgrey",
                              },
                            }}
                          >
                            <TableCell>Risikofrie plasseringer</TableCell>
                            <TableCell align="right">
                              {Math.round(
                                (liquidityPlacements.moneyMarket +
                                  liquidityPlacements.deposits) /
                                  1000
                              ).toLocaleString("en-US")}
                            </TableCell>
                            <TableCell align="right">
                              {(
                                ((liquidityPlacements.moneyMarket +
                                  liquidityPlacements.deposits) /
                                  getAssetTotal()) *
                                100
                              ).toFixed(1) + "%"}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                        <TableFooter>
                          <TableRow
                            sx={{
                              "& td": {
                                fontWeight: "bold",
                                color: "black",
                              },
                            }}
                          >
                            <TableCell>Totalt</TableCell>
                            <TableCell align="right">
                              {Math.round(
                                (liquidityPlacements.shares +
                                  liquidityPlacements.bonds +
                                  liquidityPlacements.deposits +
                                  liquidityPlacements.moneyMarket) /
                                  1000
                              ).toLocaleString("en-US")}
                            </TableCell>
                            <TableCell align="right">100.0%</TableCell>
                          </TableRow>
                        </TableFooter>
                      </Table>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid item container xs={12} sx={{ mt: 5 }}>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table
              size="small"
              sx={{
                ".MuiTableCell-head": {
                  fontWeight: "bold",
                },
                "& th": {
                  fontSize: 10,
                },
                "& td": {
                  fontSize: 10,
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="center" colSpan={8}>
                    År
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  {years.map((item, index) => (
                    <TableCell align="right">
                      {index === years.length - 1 ? "2023 YTD" : item}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={{ "& td": { fontWeight: "bold" } }}>
                  <TableCell>Inngående balanse</TableCell>
                  {years.map((item, index) => (
                    <TableCell align="right">
                      {(113803 + index * 1255 + 1500 * index).toLocaleString(
                        "en-US"
                      )}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell>Avkastning på midler</TableCell>
                  {years.map((item, index) => (
                    <TableCell align="right">
                      {(6500 + index * 495).toLocaleString("en-US")}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell>Tildelinger til stiftelsen</TableCell>
                  {years.map((item, index) => (
                    <TableCell align="right">
                      {(10000 + index * 6000).toLocaleString("en-US")}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell>Driftskostnader</TableCell>
                  {years.map((item, index) => (
                    <TableCell align="right">
                      {(-4000 - index * 240).toLocaleString("en-US")}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell>Utdeling av midler</TableCell>
                  {years.map((item, index) => (
                    <TableCell align="right">
                      {(-11000 - index * 5000).toLocaleString("en-US")}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow sx={{ "& td": { fontWeight: "bold" } }}>
                  <TableCell sx={{ backgroundColor: "lightgrey" }}>
                    Totalt
                  </TableCell>
                  {years.map((item, index) => (
                    <TableCell
                      align="right"
                      sx={{ backgroundColor: "lightgrey" }}
                    >
                      {(
                        113803 +
                        (index + 1) * 1255 +
                        1500 * (index + 1)
                      ).toLocaleString("en-US")}
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid> */}
    </>
  );
}
