import { DefaultAxiosPostRequest } from "./ApiBase";
import { Cashflow } from "./GetCashflows";
import { Dividend } from "./GetDividends";

export async function AddDividend(dividend: Omit<Dividend, "id">) : Promise<Dividend> {
    try{
        let result = await DefaultAxiosPostRequest(`AddDividend`, dividend);
        return result.data as Dividend;
    }
    catch(e){
        throw e;
    }
}
