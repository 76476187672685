import { TrialBalanceAccountDto } from "../api/GetTrialBalance";

export function getDistinctObjectsByProperty<T, U>(
    arr: T[],
    property: keyof T,
  ): T[] {
    const uniqueMap = new Map<any, T>();
    
    for (const obj of arr) {
      const key = obj[property];
      if (!uniqueMap.has(key)) {
        uniqueMap.set(key, obj);
      }
    }
  
    const uniqueObjects = Array.from(uniqueMap.values());
    return uniqueObjects;
  }
  
  export function getDistinctObjectsByPropertyAndTransform<T, U>(
    arr: T[],
    property: keyof T,
    transform: (input: T) => U
  ): U[] {
    const uniqueMap = new Map<any, T>();
    
    for (const obj of arr) {
      const key = obj[property];
      if (!uniqueMap.has(key)) {
        uniqueMap.set(key, obj);
      }
    }
  
    const uniqueObjects = Array.from(uniqueMap.values());
    let newArr : U[] = uniqueObjects.map((item) => transform(item));
    return newArr;
  }

  //Splits an array into chunks of a specified size
  export function chunkifyArray<T>(arr: T[], chunkSize: number): T[][] {
    const result = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      result.push(chunk);
    }
    return result;
  }
