import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/system";

export interface DialogWindowProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title: string;
}

export default function DialogWindow({
  open,
  onClose,
  children,
  title,
}: DialogWindowProps) {
  const theme = useTheme();
  const isMobilePhone = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Dialog
      maxWidth={"lg"}
      fullWidth
      fullScreen={isMobilePhone}
      onClose={onClose}
      open={open}
    >
      <DialogTitle
        sx={{
          backgroundColor: "primary.main",
          color: "primary.contrastText",
        }}
      >
        <Grid item container xs={12}>
          <Grid item xs={2}>
            <IconButton onClick={() => onClose()}>
              <Close sx={{ color: "primary.contrastText" }} />
            </IconButton>
          </Grid>
          <Grid item container justifyContent={"center"} xs={8}>
            {title}
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
      </DialogTitle>
      {children}
    </Dialog>
  );
}
