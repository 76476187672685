import { Refresh } from "@mui/icons-material";
import {
   Autocomplete,
   CircularProgress,
   Divider,
   Grid,
   IconButton,
   TextField,
   Tooltip,
   useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { GetFinancialStatements } from "../../api/GetFinancialStatements";
import { GetTrialBalance } from "../../api/GetTrialBalance";
import DashboardCard from "../../components/DashboardCard";
import { AuthContext } from "../../context/AuthContext";
import { DataContext } from "../../context/DataContext";
import Cash from "./Cash";
import IncomeStatement from "./IncomeStatement";
import Investments from "./Investments";
import Liquidity from "./Liquidity";
import Taxes from "./Taxes";
import AccountHighlighter from "../../components/AccountHighlighter";

export default function Financials() {
   const { setFinancialStatement, financialStatement, setTrialBalance, setClients, clients } = useContext(DataContext);
   const { loggedInUser } = useContext(AuthContext);

   const theme = useTheme();
   const isMobilePhone = useMediaQuery(theme.breakpoints.down("sm"));

   const [selectedClient, setSelectedClient] = useState<string | null>(null);
   const [validClients, setValidClients] = useState<string[]>([]);
   const [loading, setLoading] = useState<boolean>(false);
   const [cash, setCash] = useState<number>(0);

   useEffect(() => {
      if (loggedInUser) setValidClients(loggedInUser.clients);
   }, [loggedInUser]);

   useEffect(() => {
      setFinancialStatement && setFinancialStatement(null);
      setTrialBalance && setTrialBalance(null);

      if (selectedClient && setClients) {
         setClients([selectedClient]);
         return;
      }
      setClients && setClients(null);
   }, [selectedClient]);

   async function loadData() {
      try {
         if (!selectedClient) return;

         setLoading(true);
         let [fs, tb] = await Promise.all([
            GetFinancialStatements({
               timePeriods: [
                  {
                     periodFrom: "202301",
                     periodTo: "202306",
                  },
                  {
                     periodFrom: "202307",
                     periodTo: "202312",
                  },
               ],
               clients: [selectedClient],
            }),
            GetTrialBalance({
               periodFrom: "202400",
               periodTo: "202402",
               clients: [selectedClient],
               aggregateTimePeriods: true,
            }),
         ]);
         setFinancialStatement && setFinancialStatement(fs);
         setTrialBalance && setTrialBalance(tb);

         let cash = tb.filter(
            (item) =>
               item.financialStatementCaption === "Bankinnskudd, kontanter og lignende" &&
               item.accountId !== "1999" &&
               item.amount !== 0
         );
         setCash(cash.reduce((value, item) => (value += item.amount), 0));
         setLoading(false);
      } catch (e) {
         setLoading(false);
      }
   }

   return (
      <Grid item container xs={12} spacing={3}>
         <Grid item container xs={12} justifyContent={"space-between"}>
            <Grid item sx={{ width: isMobilePhone ? 250 : 300 }}>
               <Autocomplete
                  size="small"
                  sx={{
                     backgroundColor: "primary.contrastText",
                     ".MuiInputBase-root": { fontSize: 12 },
                  }}
                  ListboxProps={{ sx: { fontSize: 12 } }}
                  options={validClients}
                  value={selectedClient}
                  onChange={(event: any, value: string | null) => setSelectedClient(value)}
                  renderInput={(params) => (
                     <TextField
                        sx={{
                           fontSize: 12,
                        }}
                        {...params}
                        InputLabelProps={{ sx: { fontSize: 12 } }}
                        label="Klient"
                     />
                  )}
               />
            </Grid>
            <Grid item>
               <Tooltip title="Hent data">
                  <IconButton
                     sx={{
                        color: "primary.contrastText",
                        backgroundColor: "primary.main",
                     }}
                     onClick={() => loadData()}
                  >
                     <Refresh />
                  </IconButton>
               </Tooltip>
            </Grid>
         </Grid>
         <Grid item xs={12}>
            <Divider />
         </Grid>
         {loading ? (
            <Grid item container xs={12} justifyContent={"center"}>
               <CircularProgress sx={{ mt: 5 }} size={50} />
            </Grid>
         ) : (
            <>
               {selectedClient && financialStatement && (
                  <>
                     <Grid item xs={12} sm={6}>
                        <DashboardCard title="Bankinnskudd" component={<Cash />} size="large" />
                     </Grid>
                     <Grid item xs={12} sm={6}>
                        <DashboardCard
                           title="Mellomværende aksjonær"
                           component={
                              <AccountHighlighter
                                 accountFilter={(item) => ["2910", "2911", "2800"].includes(item.accountId)}
                                 title="Mellomværende med aksjonær"
                                 reverseAmount={true}
                              />
                           }
                           size="large"
                        />
                     </Grid>
                     {/* <Grid item xs={12} sm={6}>
                        <DashboardCard title="Investeringer" component={<Investments />} size="large" />
                     </Grid> */}
                     <Grid item xs={12}>
                        <DashboardCard title="Resultatregnskap" component={<IncomeStatement />} size="large" />
                     </Grid>
                     <Grid item xs={12}>
                        <DashboardCard
                           title="Likviditetsprognose"
                           component={<Liquidity client={selectedClient} currentPeriod="202312" startingValue={cash} />}
                           size="large"
                        />
                     </Grid>
                     <Grid item xs={12}>
                        <DashboardCard title="Skatt" component={<Taxes client={selectedClient} />} size="large" />
                     </Grid>
                  </>
               )}
            </>
         )}
      </Grid>
   );
}
