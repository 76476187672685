import { DefaultAxiosGetRequest } from "./ApiBase";

export interface WealthTax {
    id: number;
    client: string;
    amount: number;
    fiscalYear: number;
}

export interface GetWealthTaxQuery {
    clients: string[];
    years: number[];
}

export async function GetWealthTax(parameters: GetWealthTaxQuery): Promise<WealthTax[]> {
   try {
      let result = await DefaultAxiosGetRequest(`GetWealthTax`, parameters);
      return result.data as WealthTax[];
   } catch (e) {
      throw e;
   }
}
