import {
  CircularProgress,
  Grid,
  List,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import WaterfallChart from "../../components/Waterfall";
import useTheme from "@mui/system/useTheme";
import { Cashflow, GetCashflows } from "../../api/GetCashflows";
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import { DataContext } from "../../context/DataContext";
import { LibraryBooks } from "@mui/icons-material";

type LiquidityChartProps = {
  currentPeriod: string;
  client: string;
  startingValue: number;
};

export default function LiquidityChart({
  currentPeriod,
  client,
  startingValue,
}: LiquidityChartProps) {
  const theme = useTheme();
  const isMobilePhone = useMediaQuery(theme.breakpoints.down("sm"));

  const [data, setData] = useState<Cashflow[]>([]);
  const [quarterlySpend, setQuarterlySpend] = useState<number>(0);
  const [periodFrom, setPeriodFrom] = useState<string | null>(null);
  const [periodTo, setPeriodTo] = useState<string | null>(null);
  const [mode, setMode] = useState<"quarterly" | "semi-annually" | "annually">(
    "quarterly"
  );
  const { trialBalance } = useContext(DataContext);

  const [loading, setLoading] = useState<boolean>(false);

  function calculateEndPeriod() {
    let startingMonth = parseInt(currentPeriod.substring(4, 6));
    let startingYear = parseInt(currentPeriod.substring(0, 4));

    if (startingMonth === 12) {
      startingMonth = 1;
      startingYear += 1;
    } else {
      startingMonth += 1;
    }

    let startingPeriod = (startingYear * 100 + startingMonth).toString();
    setPeriodFrom(startingPeriod);
    let yearsToAdd = 0;

    let monthsToAdd =
      mode === "annually" ? 12 : mode === "semi-annually" ? 6 : 3;
    if (startingMonth + (monthsToAdd - 1) > 12) {
      monthsToAdd = monthsToAdd - 12;
      yearsToAdd += 1;
    }

    let endMonth = startingMonth + monthsToAdd - 1;
    let endYear = startingYear + yearsToAdd;

    let endPeriod = (endYear * 100 + endMonth).toString();
    return endPeriod;
  }

  async function getData() {
    if (!client || !periodTo || !periodFrom) return;
    try {
      setLoading(true);
      let data = await GetCashflows({
        periodFrom: periodFrom,
        periodTo: periodTo,
        clients: [client],
      });
      setData(data.filter((item) => !item.paidPrivately));
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, [client, periodTo]);

  useEffect(() => {
    setPeriodTo(calculateEndPeriod());
  }, [currentPeriod, mode]);

  useEffect(() => {
    if (!trialBalance) return;
    let validItems = ["Driftskostnad"];
    let amount = trialBalance
      ?.filter(
        (item) =>
          client === item.company && validItems.includes(item.accountType)
      )
      .reduce((value, item) => (value += item.amount), 0);

    let startingMonth = 1;
    let endMonth = Math.max(...trialBalance.map((item) => item.month));

    let numberOfMonths = endMonth - startingMonth + 1;
    if (amount && amount !== 0)
      setQuarterlySpend(
        -(
          (amount / numberOfMonths) *
          (mode === "annually" ? 12 : mode === "semi-annually" ? 6 : 3)
        )
      );
  }, [trialBalance, mode]);

  return (
    <Grid item container xs={12} spacing={3}>
      <Grid item xs={12}>
        <ToggleButtonGroup
          exclusive
          onChange={(e, newAlignment) => {
            if (newAlignment !== null) setMode(newAlignment);
          }}
          value={mode}
        >
          <ToggleButton sx={{ width: 100 }} size="small" value="quarterly">
            Kvartal
          </ToggleButton>

          <ToggleButton sx={{ width: 100 }} size="small" value="semi-annually">
            Halv-årlig
          </ToggleButton>
          <ToggleButton sx={{ width: 100 }} size="small" value="annually">
            Årlig
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item xs={12}>
        {/* <Typography
          variant="body2"
          sx={{ fontSize: 11 }}
          color="GrayText"
        >{`Fra ${periodFrom} til ${periodTo}`}</Typography> */}
      </Grid>
      <Grid item xs={12}>
        {loading ? (
          <Grid
            item
            container
            sx={{ minHeight: 150 }}
            justifyContent={"center"}
            xs={12}
          >
            <CircularProgress size={50} sx={{ mt: 3 }} />
          </Grid>
        ) : (
          <WaterfallChart
            options={{
              startingValue: startingValue,
              direction: isMobilePhone ? "horizontal" : "vertical",
            }}
            values={[
              { name: "Estimerte driftskostnader", value: quarterlySpend },
              ...data.map((item) => ({
                name: `${moment(item.date).format("DD-MM")} ${
                  item.description
                }`,
                value: item.amount,
              })),
            ]}
          />
        )}
      </Grid>
    </Grid>
  );
}
