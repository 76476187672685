import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import axios, { InternalAxiosRequestConfig } from "axios";

export const msalConfig: Configuration = {
   auth: {
      clientId: process.env.REACT_APP_CLIENTID ?? "",
      authority: "https://login.microsoftonline.com/4913b7e4-a2e6-4b18-89d8-4da6f39e0bbb",
      redirectUri: "/",
   },
};



export const msalInstance = new PublicClientApplication(msalConfig);

axios.interceptors.request.use(
   async (config: InternalAxiosRequestConfig) => {
      if (config.headers) {
         config.headers["Content-Type"] = "application/json";
      }

      const account = msalInstance.getAllAccounts()[0];

      console.log(`Scope: api://${msalConfig.auth.clientId}/user_impersonation`);

      if (account) {
         const accessTokenResponse = await msalInstance.acquireTokenSilent({
            scopes: [`api://${msalConfig.auth.clientId}/user_impersonation`],
            account: account,
         });

         if (accessTokenResponse) {
            const accessToken = accessTokenResponse.accessToken;

            if (config.headers && accessToken) {
               config.headers["Authorization"] = "Bearer " + accessToken;
            }
         }
      }
      return config;
   },
   (error) => {
      Promise.reject(error);
   }
);
