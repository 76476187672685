import { Grid } from "@mui/material";
import React, { useState } from "react";
import { User } from "../api/GetUser";
import { useMsal } from "@azure/msal-react";

export type ContextProps = {
   children: React.ReactNode;
};

type AuthContextType = {
   loggedInUser: User | null;
   setLoggedInUser: (user: User | null) => void;
   loading: boolean;
   toggleLoading: () => void;
   logOut: () => void;
};

export const AuthContext = React.createContext<Partial<AuthContextType>>({});

export const AuthContextProvider = ({ children }: ContextProps) => {
   const [loggedInUser, setLoggedInUser] = useState<User | null>(null);
   const [loading, setLoading] = useState<boolean>(false);
   const { instance } = useMsal();

   const toggleLoading = () => {
      setLoading((state) => !state);
   };

   const logOut = () => {
      setLoggedInUser(null);
      instance.logoutRedirect({
         onRedirectNavigate: (url) => {
            // Return false if you would like to stop navigation after local logout
            return false;
         },
      });
   };
   return (
      <AuthContext.Provider
         value={{
            loggedInUser,
            setLoggedInUser,
            logOut,
            loading,
            toggleLoading,
         }}
      >
         {children}
      </AuthContext.Provider>
   );
};
