import { DefaultAxiosDownloadTrigger } from "./ApiBase";

export type DisbursementValue = {
   category: string;
   disbursement: number;
   budget: number;
};

export type DisbursementSlideProps = {
   year: number;
   disbursements: DisbursementValue[];
};

export default async function DownloadDisbursementSlide(disbursements: DisbursementValue[], year: number) {
   const props: DisbursementSlideProps = {
      year: year,
      disbursements: disbursements,
   };

   DefaultAxiosDownloadTrigger(`CreatePowerpoint`, props);
}
