type GriegLogoProps = {
  width?: number;
  height?: number;
};

export const GriegLogo = ({ width = 40, height = 40 }: GriegLogoProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.926 27.585V30.6559H19.5254V21.3655H23.7005C25.699 21.3655 27.0748 22.4997 27.0748 24.5281C27.0748 25.9392 26.3693 26.9041 25.2298 27.3274L27.4082 30.6559H24.7148L22.7745 27.585H21.926ZM21.926 25.6447H23.4377C24.3002 25.6447 24.6936 25.2055 24.6936 24.5246C24.6936 23.8437 24.3002 23.4045 23.4377 23.4045H21.926V25.6447Z"
        fill="white"
      ></path>
      <path
        d="M31.4018 30.6559H28.9658V21.3655H31.4018V30.6559Z"
        fill="white"
      ></path>
      <path
        d="M40.1926 21.3655V23.4328H35.9416V24.925H39.8222V26.9464H35.9416V28.5833H40.1926V30.6647H33.5498V21.3655H40.1926Z"
        fill="white"
      ></path>
      <path
        d="M14.0484 25.4649V27.3805H15.3996V28.31C15.0476 28.5478 14.6279 28.6648 14.2037 28.6434C12.7925 28.6434 11.8436 27.6133 11.8436 26.0399C11.8436 24.8052 12.4944 23.9391 13.4152 23.5705C14.0573 23.3112 14.8263 23.3606 15.3555 23.4382C15.9454 23.5296 16.5199 23.7018 17.0629 23.9497V21.8366C16.2339 21.3974 15.3035 21.185 14.3659 21.221C11.4149 21.221 9.40234 23.1207 9.40234 26.0399C9.40234 28.8392 11.2174 30.8024 14.1225 30.8024C15.4102 30.8024 16.6043 30.4232 17.5427 29.5677V25.4649H14.0484Z"
        fill="white"
      ></path>
      <path
        d="M46.4438 25.4648V27.3803H47.7896V28.3099C47.4376 28.5477 47.0179 28.6647 46.5937 28.6433C45.1826 28.6433 44.2336 27.6132 44.2336 26.0398C44.2336 24.8051 44.8845 23.939 45.8052 23.5703C46.4473 23.311 47.2163 23.3604 47.7455 23.438C48.3354 23.5295 48.9099 23.7016 49.453 23.9496V21.8364C48.6249 21.3978 47.6958 21.1855 46.7595 21.2208C43.8085 21.2208 41.7959 23.1276 41.7959 26.0468C41.7959 28.8461 43.6109 30.8093 46.5161 30.8093C47.8037 30.8093 48.9979 30.4301 49.9363 29.5746V25.4648H46.4438Z"
        fill="white"
      ></path>
      <path
        d="M44.9994 44.9999H4.99965V5.00014H49.7495V-0.000488281H-0.000976562V50.0005H50V33.0319H44.9994V44.9999Z"
        fill="white"
      ></path>
    </svg>
  );
};
