import { createContext, useState } from "react";
import { TrialBalanceAccountDto } from "../api/GetTrialBalance";
import { FinancialStatement } from "../api/GetFinancialStatements";

type ContextProps = {
  children: React.ReactNode;
};

type DataContextProps = {
  clients: string[] | null;
  setClients: React.Dispatch<React.SetStateAction<string[] | null>>;
  trialBalance: TrialBalanceAccountDto[] | null;
  setTrialBalance: React.Dispatch<
    React.SetStateAction<TrialBalanceAccountDto[] | null>
  >;
  financialStatement: FinancialStatement[] | null;
  setFinancialStatement: (fs: FinancialStatement[] | null) => void;
  priorYearTrialBalance: TrialBalanceAccountDto[] | null;
  setPriorYearTrialBalance: React.Dispatch<
    React.SetStateAction<TrialBalanceAccountDto[] | null>
  >;
  priorYearFinancialStatement: FinancialStatement[] | null;
  setPriorYearFinancialStatement: (fs: FinancialStatement[] | null) => void;
};

export const DataContext = createContext<Partial<DataContextProps>>({});

export const DataContextProvider = ({ children }: ContextProps) => {
  const [trialBalance, setTrialBalance] = useState<
    TrialBalanceAccountDto[] | null
  >(null);
  const [priorYearTrialBalance, setPriorYearTrialBalance] = useState<
    TrialBalanceAccountDto[] | null
  >(null);
  const [financialStatement, setFinancialStatement] = useState<
    FinancialStatement[] | null
  >(null);
  const [priorYearFinancialStatement, setPriorYearFinancialStatement] =
    useState<FinancialStatement[] | null>(null);
  const [clients, setClients] = useState<string[] | null>(null);

  return (
    <DataContext.Provider
      value={{
        trialBalance,
        setTrialBalance,
        priorYearTrialBalance,
        setPriorYearTrialBalance,
        financialStatement,
        setFinancialStatement,
        clients,
        setClients,
        priorYearFinancialStatement,
        setPriorYearFinancialStatement,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
